import React, { useRef } from 'react'
import Loader from './help-components/loader'
import {useCreateTag, useGetTag, useListTags, useUpdateTag} from '../api/apis/tag'
import { Table } from './table'
import { Box } from './general-purpose'
import { ColumnDef } from '@tanstack/react-table'
import Checkbox from './help-components/checkbox'
import { Tag } from '../api/models/tag'
import TagsSidebar from './sidebar-components/tags-sidebar'
import { GrCheckmark } from 'react-icons/gr'
import { getBaseUri } from '../api/api'
import { generateQRCode } from './qrcodeUtils'
import { CustomFieldComponentProps } from './sidebar-components/table-sidebar'

export default function GetTags() {
    const {tags, error, loading, refresh} = useListTags(0, 1000);
    const [data, setData] = React.useState<Tag[]>([]);
    const {createTag} = useCreateTag();
    const {updateTag} = useUpdateTag();

    const sidebarUpdate =(results: (Tag | undefined)[]) => {
        console.log("parent");
        results.forEach((user) => {
            console.log(user?.id);
          });
 
 
 setData(data.map(item => results.find(results => results?.id === item.id) ?? item))}  
    
    const columns = React.useMemo<ColumnDef<Tag>[]>(
        () => [
            {
                id: 'select',
                header: ({table}) => (
                    <Checkbox
                        {...{
                            checked: table.getIsAllRowsSelected(),
                            disabled: !table.options.enableRowSelection,
                            indeterminate: table.getIsSomeRowsSelected(),
                            onChange: table.getToggleAllRowsSelectedHandler()
                        }}
                    />
                ),
                cell: ({row}) => (
                    <Checkbox
                        {...{
                            checked: row.getIsSelected(),
                            disabled: !row.getCanSelect(),
                            indeterminate: row.getIsSomeSelected(),
                            onChange: row.getToggleSelectedHandler()
                        }}
                    />
                ),
            },
            {
                id: 'text',
                accessorKey: 'text',
                header: 'Tag name',
                cell: info => info.getValue()
            },
            {
                id: 'location',
                accessorKey: 'location',
                header: 'Location',
                cell: info => info.getValue()
            },
            {
                id: 'enabled',
                accessorKey: 'enabled',
                header: 'Enabled',
                cell: (info) => {
                  let isEnabled = info.getValue();
                  return isEnabled ? <GrCheckmark/> : '';
                },
              },
            ],
            []
          );

	React.useEffect(() => {
		tags && setData(tags);
	}, [tags]);

    if(error) {
        return (
            <>
                <p>Error occurred: {JSON.stringify(error)}</p>
                <button onClick={refresh}>Try again</button>
            </>
        )
    }

    function createItem(item: Partial<Tag>): Promise<Tag> {
        return createTag({
            text: '',
            location: '',
            enabled: false,
            require_authentication: false,
            activities: [],
            token: '',
            type: 'static',
            device: null,
            ...item,
        })
    }

    function updateItem(old: Tag, item: Partial<Tag>): Promise<Tag> {
        return updateTag({
            ...old,
            ...item,
        })
    }

    
    

    return (
        <Box css={{ height: '100vh', overflowY: 'scroll' }}>
          {loading ? (
            <Loader />
          ) : (
            <Table<Tag>
              data={data}
              columns={columns}
              customSidebar={TagsSidebar}
              
              sidebarInputs={[
                { key: 'text', label: 'Tag name', fieldType: "text" },
                { key: 'location', label: 'Tag location',  fieldType: "text"  },
                {
                  key: 'enabled',
                  label: 'Enabled',
                  fieldType: "checkbox",
                 
                },
                {
                  key: 'require_authentication',
                  label: 'Require authentication',
                  type: 'checkbox',
                  fieldType: "checkbox",
                },
                {
                  key: 'id',
                  label: 'qr code',
                  mode: 'editOnly',
                  fieldType: "custom",
                  customComponent: QRCodeField
                },
              ]}
              createItem={createItem}
              updateItem={updateItem}
              sidebarUpdateProp={sidebarUpdate}
            />
          )}
        </Box>
      );
}

export function QRCodeField(props: CustomFieldComponentProps<Tag>) {
    // Define the data for the QR code
    const Id = parseInt(props.state.current);
    const {tag} = useGetTag(Id);
    const token = tag?.token;

    const qrCodeData = `${getBaseUri()}/t/${token}`;

    // Create a reference to the QR code image element
    const qrCodeRef = useRef<HTMLImageElement>(null);

    // Generate the QR code and assign it to the image element referenced by qrCodeRef
    generateQRCode(qrCodeData, qrCodeRef);

    // Render the QR code in the component's JSX
    return (
        <>
            <div ref={qrCodeRef}>
                {/* Render the image element with the QR code data */}
                {qrCodeData && (
                    <img
                        src={qrCodeData}
                        alt="QR Code"
                    />
                )}
            </div>
        </>
    );
}
