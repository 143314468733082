import { useMemo } from 'react';
import {
  NoId,
  ProcessedApiRequestResult,
  ProcessedApiResult,
  useApi,
  useApiRequest,
} from '../api';
import { MinimalUser, User, Users } from '../models/user';
import { MessageId } from '../models/messages';
import { Password } from '../models/password';

const PATH = 'user';

export type UseListUsersResult = ProcessedApiResult<{
  /** List of users if the request succeeded. */
  users: User[] | undefined;
}>;

/**
 * React hook for listing all users (in pages).
 * @param page Page number, starting from 0.
 * @param limit Number of items to return (max: 1000).
 * @returns A list of users.
 */
export function useListUsers(page: number, limit: number): UseListUsersResult {
  const params = useMemo(() => ({ page, limit }), [limit, page]);
  const res = useApi<Users>({
    path: `${PATH}/`,
    params,
  });
  return useMemo(() => ({ ...res, users: res.result?.users }), [res]);
}

export type UseGetUserResult = ProcessedApiResult<{
  /** The user if the request succeeded. */
  user: User | undefined;
}>;

/**
 * React hook for getting a single user.
 * @param id Identifier of user.
 * @returns The user with matching `id`.
 */
export function useGetUser(id: number): UseGetUserResult {
  const res = useApi<User>({
    path: `${PATH}/${id}/`,
  });
  return useMemo(() => ({ ...res, user: res.result }), [res]);
}

export type UseCreateUserResult = ProcessedApiRequestResult<{
  /** The newest created user with a unique `id`. */
  user: User | undefined;
  /**
   * Create a new user.
   * @param user The user to create.
   * @returns The newly created user.
   */
  createUser(body: NoId<MinimalUser>): Promise<User>;
}>;

/**
 * Hook for creating new users.
 */
export function useCreateUser(): UseCreateUserResult {
  const res = useApiRequest<User, NoId<MinimalUser>>({
    path: `${PATH}/`,
    method: 'POST',
  });
  return useMemo(
    () => ({ ...res, user: res.result, createUser: res.call }),
    [res],
  );
}

export type UseUpdateUserResult = ProcessedApiRequestResult<{
  /** The newest updated user. */
  user: User | undefined;
  /**
   * Update the user.
   * @param id Id of the user to update.
   * @returns The updated user.
   */
  updateUser(body: MinimalUser): Promise<User>;
}>;

/**
 * Hook for updating users.
 */
export function useUpdateUser(): UseUpdateUserResult {
  const res = useApiRequest<User, MinimalUser>({
    path: `${PATH}/{0}/`,
    method: 'PUT',
  });
  return useMemo(
    () => ({
      ...res,
      user: res.result,
      updateUser: body => res.call(body.id, body),
    }),
    [res],
  );
}

export type UseChangeUserPasswordResult = ProcessedApiRequestResult<{
  /**
   * Change the current user's password.
   * @param password New and existing passwords.
   */
  changePassword(password: Password): Promise<null>;
}>;

/**
 * Hook for changing users' passwords.
 * @returns Unknown.
 */
export function useChangeUserPassword(): UseChangeUserPasswordResult {
  const res = useApiRequest<null, Password>({
    path: `${PATH}/password_change/`,
    method: 'PUT',
  });
  return useMemo(() => ({ ...res, changePassword: res.call }), [res]);
}

export type UseResetUserPasswordResult = ProcessedApiRequestResult<{
  /** The new reseted password. */
  newPassword: Password | undefined;
  /**
   * Reset user's password.
   * @param userId Target user's id.
   * @returns The message id.
   */
  resetPassword(userId: number): Promise<Password>;
}>;

/**
 * Hook for resetting user passwords.
 * @returns A new password object for the user.
 */
export function useResetUserPassword(): UseResetUserPasswordResult {
  const res = useApiRequest<Password>({
    path: `${PATH}/{0}/password_reset/`,
    method: 'PUT',
  });
  return useMemo(
    () => ({ ...res, newPassword: res.result, resetPassword: res.call }),
    [res],
  );
}

export type UseResetUserPasswordEmailResult = ProcessedApiRequestResult<{
  /** The newest message id. */
  messageId: string | undefined;
  /**
   * Reset user's password via email.
   * @param userId Target user's id.
   * @returns The message id.
   */
  resetPassword(userId: number): Promise<MessageId>;
}>;

/**
 * Hook for resetting user passwords via email.
 */
export function useResetUserPasswordEmail(): UseResetUserPasswordEmailResult {
  const res = useApiRequest<MessageId>({
    path: `${PATH}/{0}/password_reset/email/`,
    method: 'PUT',
  });
  return useMemo(
    () => ({
      ...res,
      messageId: res.result?.message_id,
      resetPassword: res.call,
    }),
    [res],
  );
}

export type UseResetUserPasswordSmsResult = ProcessedApiRequestResult<{
  /** The message id. */
  messageId: string | undefined;
  /**
   * Reset the user's password via SMS.
   * @param userId Target user's id.
   * @returns The message id.
   */
  resetPassword(userId: number): Promise<MessageId>;
}>;

/**
 * Hook for resetting user's password via SMS.
 */
export function useResetUserPasswordSms(): UseResetUserPasswordSmsResult {
  const res = useApiRequest<MessageId>({
    path: `${PATH}/{0}/password_reset/sms/`,
    method: 'PUT',
  });
  return useMemo(
    () => ({
      ...res,
      messageId: res.result?.message_id,
      resetPassword: res.call,
    }),
    [res],
  );
}
