import { styled } from "../stitches.config";
import { keyframes } from "@stitches/react";

export const Table = styled("table", {
	width: "100%",
	scrollbarWidth: "none",
	margin: "0",
	padding: "0 20px 10px 20px",
	borderCollapse: "separate",
	borderSpacing: "0 5px",
	position: "sticky",

	thead: {
		color: "$darkBlue",

		"&:hover": {
			"td:hover": {
				backgroundColor: "#efefef",
				cursor: "grab",

				svg: {
					visibility: "visible",
				},
			},
		},

		svg: {
			color: "gray",
			cursor: "pointer",
		},
	},

	tbody: {
		color: "gray",

		button: {
			color: "gray",
		},
		"&:hover": {
			"tr:hover td": {
				backgroundColor: "#efefef",
				cursor: "grab",
			},
		},
	},

	tr: {
		height: "50px",
		borderRadius: "5px",
	},

	td: {
		backgroundColor: "white",
		padding: "10px",
	},

	"tr td:first-child": {
		borderTopLeftRadius: "inherit",
		borderBottomLeftRadius: "inherit",
		width: "40px",
	},

	"tr td:last-child": {
		borderTopRightRadius: "inherit",
		borderBottomRightRadius: "inherit",
	},

	input: {
		width: "20px",
		height: "20px",
		cursor: "pointer",
	},
});

export const ButtonsHolder = styled("div", {
	position: "sticky",
	padding: "83px 20px 0 15px",
	display: "flex",
	justifyContent: "space-between",

	variants: {
		size: {
			large: {
				paddingBottom: "10px",
				button: {
					marginTop: "10px",
					marginRight: "-6px",
					marginBottom: "4px",
				},
			},
		},
	},

	svg: {
		overflow: "visible",
		marginLeft: "5px",
		color: "gray",
		cursor: "pointer",
	},
});

const spinner = keyframes({
	"0%, 100%": {
		boxShadow: `0em -2.6em 0em 0em #ffffff, 1.8em -1.8em 0 0em rgba(34, 57, 115, 0.2), 2.5em 0em 0 0em rgba(34, 57, 115, 0.2), 1.75em 1.75em 0 0em rgba(34, 57, 115, 0.2), 0em 2.5em 0 0em rgba(34, 57, 115, 0.2), -1.8em 1.8em 0 0em rgba(34, 57, 115, 0.2), -2.6em 0em 0 0em rgba(34, 57, 115, 0.5), -1.8em -1.8em 0 0em rgba(34, 57, 115, 0.7)`,
	},
	"12.5%": {
		boxShadow: `0em -2.6em 0em 0em rgba(34, 57, 115, 0.7), 1.8em -1.8em 0 0em #ffffff, 2.5em 0em 0 0em rgba(34, 57, 115, 0.2), 1.75em 1.75em 0 0em rgba(34, 57, 115, 0.2), 0em 2.5em 0 0em rgba(34, 57, 115, 0.2), -1.8em 1.8em 0 0em rgba(34, 57, 115, 0.2), -2.6em 0em 0 0em rgba(34, 57, 115, 0.2), -1.8em -1.8em 0 0em rgba(34, 57, 115, 0.5)`,
	},
	"25%": {
		boxShadow: `0em -2.6em 0em 0em rgba(34, 57, 115, 0.5), 1.8em -1.8em 0 0em rgba(34, 57, 115, 0.7), 2.5em 0em 0 0em #ffffff, 1.75em 1.75em 0 0em rgba(34, 57, 115, 0.2), 0em 2.5em 0 0em rgba(34, 57, 115, 0.2), -1.8em 1.8em 0 0em rgba(34, 57, 115, 0.2), -2.6em 0em 0 0em rgba(34, 57, 115, 0.2), -1.8em -1.8em 0 0em rgba(34, 57, 115, 0.2)`,
	},
	"37.5%": {
		boxShadow: `0em -2.6em 0em 0em rgba(34, 57, 115, 0.2), 1.8em -1.8em 0 0em rgba(34, 57, 115, 0.5), 2.5em 0em 0 0em rgba(34, 57, 115, 0.7), 1.75em 1.75em 0 0em #ffffff, 0em 2.5em 0 0em rgba(34, 57, 115, 0.2), -1.8em 1.8em 0 0em rgba(34, 57, 115, 0.2), -2.6em 0em 0 0em rgba(34, 57, 115, 0.2), -1.8em -1.8em 0 0em rgba(34, 57, 115, 0.2)`,
	},
	"50%": {
		boxShadow: `0em -2.6em 0em 0em rgba(34, 57, 115, 0.2), 1.8em -1.8em 0 0em rgba(34, 57, 115, 0.2), 2.5em 0em 0 0em rgba(34, 57, 115, 0.5), 1.75em 1.75em 0 0em rgba(34, 57, 115, 0.7), 0em 2.5em 0 0em #ffffff, -1.8em 1.8em 0 0em rgba(34, 57, 115, 0.2), -2.6em 0em 0 0em rgba(34, 57, 115, 0.2), -1.8em -1.8em 0 0em rgba(34, 57, 115, 0.2)`,
	},
	"62.5%": {
		boxShadow: `0em -2.6em 0em 0em rgba(34, 57, 115, 0.2), 1.8em -1.8em 0 0em rgba(34, 57, 115, 0.2), 2.5em 0em 0 0em rgba(34, 57, 115, 0.2), 1.75em 1.75em 0 0em rgba(34, 57, 115, 0.5), 0em 2.5em 0 0em rgba(34, 57, 115, 0.7), -1.8em 1.8em 0 0em #ffffff, -2.6em 0em 0 0em rgba(34, 57, 115, 0.2), -1.8em -1.8em 0 0em rgba(34, 57, 115, 0.2)`,
	},
	"75%": {
		boxShadow: `0em -2.6em 0em 0em rgba(34, 57, 115, 0.2), 1.8em -1.8em 0 0em rgba(34, 57, 115, 0.2), 2.5em 0em 0 0em rgba(34, 57, 115, 0.2), 1.75em 1.75em 0 0em rgba(34, 57, 115, 0.2), 0em 2.5em 0 0em rgba(34, 57, 115, 0.5), -1.8em 1.8em 0 0em rgba(34, 57, 115, 0.7), -2.6em 0em 0 0em #ffffff, -1.8em -1.8em 0 0em rgba(34, 57, 115, 0.2)`,
	},
	"87.5%": {
		boxShadow: `0em -2.6em 0em 0em rgba(34, 57, 115, 0.2), 1.8em -1.8em 0 0em rgba(34, 57, 115, 0.2), 2.5em 0em 0 0em rgba(34, 57, 115, 0.2), 1.75em 1.75em 0 0em rgba(34, 57, 115, 0.2), 0em 2.5em 0 0em rgba(34, 57, 115, 0.2), -1.8em 1.8em 0 0em rgba(34, 57, 115, 0.5), -2.6em 0em 0 0em rgba(34, 57, 115, 0.7), -1.8em -1.8em 0 0em #ffffff`,
	},
});

export const Loader = styled("span", {
	fontSize: "10px",
	width: "1em",
	height: "1em",
	borderRadius: "50%",
	animation: `${spinner} 1.1s infinite`,
});

export const LargeButton = styled("div", {
	backgroundColor: "#2B5C77",
	borderRadius: "3px",
	padding: "12px 26px",
	fontSize: "14px",
	color: "white",
	cursor: "pointer",
});
