import React from "react";
import { Box } from "./general-purpose";

import * as Styled from "./navbar.styled";
import * as Icons from "@radix-ui/react-icons";

import Toolbar from "./toolbar";
import { useLocation } from "react-router-dom";

export default function Navbar() {
	let storageToggled = JSON.parse(localStorage.getItem("toggled") || "{}");
    let location = useLocation();

	// saving toggled state into local storage to prevent unexpected behaviour

	const [toggled, setToggled] = React.useState<boolean>(
		storageToggled === null ? true : storageToggled
	);

	storageToggled !== toggled &&
		localStorage.setItem("toggled", JSON.stringify(toggled));

	return (
		<Box css={{ zIndex: 1 }}>
			<Toolbar />

			<Styled.NavigationBar {...(toggled && { size: "small" })}>
				<Styled.NavigationList>
                    <Styled.LinkItem to="/dashboard" state={location.pathname === "/dashboard" ? "selected" : undefined}>
                        <Icons.BarChartIcon />
                        <span>Dashboard</span>
                    </Styled.LinkItem>

					<Styled.LinkItem to="/users" state={location.pathname === "/users" ? "selected" : undefined}>
						<Icons.PersonIcon />
						<span>Users</span>
					</Styled.LinkItem>

					<Styled.LinkItem to="/activities" state={location.pathname === "/activities" ? "selected" : undefined}>
						<Icons.DesktopIcon />
						<span>Activities</span>
					</Styled.LinkItem>

					<Styled.LinkItem to="/tags" state={location.pathname === "/tags" ? "selected" : undefined}>
						<Icons.BookmarkIcon />
						<span>Tags</span>
					</Styled.LinkItem>

					<Styled.LinkItem to="/events" state={location.pathname === "/events" ? "selected" : undefined}>
						<Icons.StarIcon />
						<span>Events</span>
					</Styled.LinkItem>

					<Styled.LinkItem to="/reports" state={location.pathname === "/reports" ? "selected" : undefined}>
						<Icons.Pencil2Icon />
						<span>Reports</span>
					</Styled.LinkItem>

					<Styled.LinkItem to="/messages" state={location.pathname === "/messages" ? "selected" : undefined}>
						<Icons.EnvelopeClosedIcon />
						<span>Announcements</span>
					</Styled.LinkItem>

					<Styled.LinkItem to="/settings" state={location.pathname === "/settings" ? "selected" : undefined}>
						<Icons.GearIcon />
						<span>Settings</span>
					</Styled.LinkItem>

					<Styled.LinkItem
                        to={""}
						style={{ position: "fixed", bottom: "14px" }}
						onClick={() => (toggled ? setToggled(false) : setToggled(true))}
					>
						<Icons.RowsIcon />
						<span>Toggle sidebar</span>
					</Styled.LinkItem>
				</Styled.NavigationList>
			</Styled.NavigationBar>
		</Box>
	);
}
