import React from 'react';

export default function Checkbox({ indeterminate, ...rest }: { indeterminate?: boolean; } & React.HTMLProps<HTMLInputElement>) {
    const ref = React.useRef<HTMLInputElement>(null!);

    React.useEffect(() => {
        if (typeof indeterminate === 'boolean') {
            ref.current.indeterminate = !rest.checked && indeterminate;
        }
    }, [ref, indeterminate, rest.checked]);

    return (
        <input type='checkbox' ref={ref} {...rest} />
    );
}
