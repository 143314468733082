import { useRef, useState } from "react";
import { Tag } from "../../api/models/tag";
import { useSidebarRef } from "../sidebar";
import TableSidebar, { TableSidebarProps } from "./table-sidebar";
import * as Styled from "./tags-sidebar.styled";
import TagMessages from "../tag-messages";
import { TagActivitiesSidebar } from "./tags-activities-sidebar";

export default function TagsSidebar(props: TableSidebarProps<Tag>) {
	const [tab, setTab] = useState(0);
	const sidebarRef = useSidebarRef();
	const sidebarCustom = useRef<HTMLDivElement>(null);

	function MakeButton(thisTab: number) {
		return (
			<Styled.TabButton
				onClick={() => (sidebarRef.hasChanges ? void 0 : setTab(thisTab))}
				state={tab === thisTab ? "selected" : undefined}
			>
				Tab{thisTab}
			</Styled.TabButton>
		);
	}

	return (
		<>
			{MakeButton(0)}
			{MakeButton(1)}
			{MakeButton(2)}
			{tab === 0 && <TableSidebar {...props} />}
			{tab === 1 && <TagActivitiesSidebar {...props} />}
			{tab === 2 && <TagMessages {...props} />}
			<div ref={sidebarCustom}></div>
		</>
	);
}
