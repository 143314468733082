import { styled } from '../stitches.config'
import { Text } from './general-purpose'

import * as Icons from '@radix-ui/react-icons'
import background from '../Assets/Images/hiking-the-ridge.png'

export const Container = styled('div', {
    width: '100vw',
    height: '100vh',
    display: 'inline-grid',
    placeContent: 'center',
    backgroundImage: `url(${background})`,
    backgroundSize: 'cover'
})

export const LoginBox = styled('div', {
    display: 'inline-grid',
    gridTemplateRows: '1fr',
    gridTemplateColumns: '1fr',
    placeContent: 'center',
    background: 'rgba(255, 255, 255, 0.35)',
    borderRadius: '20px',
    width: '494px',
    height: '584px'
})

export const InputField = styled('input', {
    background: 'rgba(255, 255, 255, 0.75)',
    borderRadius: '9px',
    border: 'none',
    cursor: 'text',
    fontSize: '18px',
    lineHeight: '22px',
    color: 'gray',
    outline: 'none',
    boxSizing: 'border-box',
    padding: '20px',
    width: '434px',
})

const CheckboxContainer = styled('div',  {
    'input[type=checkbox]': {
        display: 'none'
    },

    'input[type=checkbox] ~ label': {
        zIndex: '9999',
        position: 'relative'
    },
    
    'input[type=checkbox]:checked + span': {
        opacity: '1',
        marginLeft: '12px',
        marginTop: '2.5px'
    },

    'input[type=checkbox]:checked ~ label, svg': {
        color: '$primary',
    },

    'input[type=checkbox]:checked ~ label::before': {
        border: '2px solid $primary',
    },

    'label::before': {
        content: '',
        display: 'inline-block',
        width: '20px',
        height: '20px',
        border: '2px solid grey',
        borderRadius: '2.6px',
        verticalAlign: 'middle',
        marginLeft: '10px',
        marginRight: '10px'
    }
})

export const Checkbox = () => {    
    return (
        <CheckboxContainer>
            <input type='checkbox' id='rememberMe' name='rememberMe' />
            <Text css={{
                zIndex: '0',
                opacity: '0',
                position: 'absolute'
            }}>
                <Icons.CheckIcon width='20px' height='20px' />
            </Text>
            <label htmlFor='rememberMe'>Remember me</label>
        </CheckboxContainer>
    )
}

export const LoginButton = styled('button', {
    width: '335px',
    height: '47px',
    background: '$primary',
    borderRadius: '48px',
    color: 'white',
    fontSize: '22px',
    border: 'none',
    cursor: 'pointer'
})

export const LoginText = styled('span', {
    color: '$primary'
})

export const LoginLogo = styled('img', {
    width: '221px',
    marginBottom: '-18px'
})
