import React from 'react';
import * as Styled from '../table.styled';
import { Box } from '../general-purpose';

export default function Loader() {
    return (
        <Box css={{ height: '100vh', display: 'grid', placeContent: 'center' }}>
            <Styled.Loader />
        </Box>
    );
}
