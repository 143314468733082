import { useMemo } from 'react';
import {
  NoId,
  ProcessedApiRequestResult,
  ProcessedApiResult,
  makeParamString,
  useApi,
  useApiRequest,
} from '../api';
import { Activity, Activities } from '../models/activity';

const PATH = 'activity';

export type UseListActivitiesResult = ProcessedApiResult<{
  /** List of activities if the request succeeded. */
  activities: Activity[] | undefined;
}>;

/**
 * React hook for listing all activities in pages.
 * @param page Page number, starting from 0.
 * @param limit Number of items to return (max: 1000).
 * @returns A list of activities.
 */
export function useListActivities(
  page: number,
  limit: number,
): UseListActivitiesResult {
  const params = useMemo(() => ({ page, limit }), [limit, page]);
  const res = useApi<Activities>({
    path: `${PATH}/`,
    params,
  });
  return useMemo(() => ({ ...res, activities: res.result?.activities }), [res]);
}

export type UseListActivitiesRequestResult = ProcessedApiRequestResult<{
  /** List of activities if the request succeeded. */
  activities: Activity[] | undefined;
  /**
   * List all activities in pages.
   * @param page Page number, starting from 0.
   * @param limit Number of items to return (max: 1000).
   * @returns A list of activities.
   */
  listActivities(page: number, limit: number): Promise<Activities>;
}>;

/**
 * Hook for listing activities in pages.
 */
export function useListActivitiesRequest(): UseListActivitiesRequestResult {
  const res = useApiRequest<Activities>({
    path: `${PATH}/{0}`,
  });
  return useMemo(
    () => ({
      ...res,
      activities: res.result?.activities,
      listActivities: (page, limit) =>
        res.call(makeParamString({ page, limit })),
    }),
    [res],
  );
}

export type UseGetActivityResult = ProcessedApiResult<{
  /** The activity if the request succeeded. */
  activity: Activity | undefined;
}>;

/**
 * React hook for getting a single activity.
 * @param id Identifier of activity.
 * @returns The activity with matching `id`.
 */
export function useGetActivity(id: number): UseGetActivityResult {
  const res = useApi<Activity>({
    path: `${PATH}/${id}/`,
  });
  return useMemo(() => ({ ...res, activity: res.result }), [res]);
}

export type UseGetActivityRequestResult = ProcessedApiRequestResult<{
  /** The activity if the request succeeded. */
  activity: Activity | undefined;
  /**
   * Get a single activity.
   * @param id Identifier of activity.
   */
  getActivity(id: number): Promise<Activity>;
}>;

/**
 * Hook for getting a single activity.
 */
export function useGetActivityRequest(): UseGetActivityRequestResult {
  const res = useApiRequest<Activity>({
    path: `${PATH}/{0}/`,
  });
  return useMemo(
    () => ({
      ...res,
      activity: res.result,
      getActivity: res.call,
    }),
    [res],
  );
}

export type UseCreateActivityResult = ProcessedApiRequestResult<{
  /** The newest created activity with a unique `id`. */
  activity: Activity | undefined;
  /**
   * Create a new activity.
   * @param activity The activity to create.
   * @returns The newly created activity.
   */
  createActivity(activity: NoId<Activity>): Promise<Activity>;
}>;

/**
 * Hook for creating new activities.
 */
export function useCreateActivity(): UseCreateActivityResult {
  const res = useApiRequest<Activity, NoId<Activity>>({
    path: `${PATH}/`,
    method: 'POST',
  });
  return useMemo(
    () => ({ ...res, activity: res.result, createActivity: res.call }),
    [res],
  );
}

export type UseUpdateActivityResult = ProcessedApiRequestResult<{
  /** The updated activity. */
  activity: Activity | undefined;
  /**
   * Update the activity.
   * @param activity New values for the activity.
   * @returns The updated activity.
   */
  updateActivity(activity: Activity): Promise<Activity>;
}>;

/**
 * Hook for updating activities.
 */
export function useUpdateActivity(): UseUpdateActivityResult {
  const res = useApiRequest<Activity, Activity>({
    path: `${PATH}/{0}/`,
    method: 'PUT',
  });
  return useMemo(
    () => ({
      ...res,
      activity: res.result,
      updateActivity: activity => res.call(activity.id, activity),
    }),
    [res],
  );
}
