/*
 * Simple wrapper over `fetch` to enable easy swapping of `fetch` to another library
 * if needed.
 */

export type QueryInit = { [key: string]: any }[];

export type Request = {
  uri: string;
  method?: string;
  headers?: HeadersInit;
  body?: BodyInit;
};

export default async function request(req: Request) {
  return fetch(req.uri, {
    method: req.method,
    headers: req.headers,
    body: req.body,
  });
}
