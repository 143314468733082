import { styled } from '../stitches.config'

export const Sidebar = styled('div', {
    overflow: 'auto',
    height: 'calc(100vh - 82px)',
    paddingTop: '82px',
    width: "400px",
    left: "calc(100vw + 10px)",
    backgroundColor: "white",
    position: "absolute",
    boxShadow: '0px 0px 8px rgba(0, 0, 0, 0.2)',
    transition: 'left .1s',

    variants: {
        state: {
            visible: {
                left: "calc(100vw - 400px)",
            }
        }
    }
})

export const CloseButton = styled('button', {
    border: "0px",
    backgroundColor: "transparent",
    float: "right",
    cursor: "pointer"
})

