import * as IsoDuration from 'iso8601-duration';

export class Duration {
  static pattern = IsoDuration.pattern;
  static parse = (durationString: string) =>
    new Duration(durationString).toSeconds() * 1000;
  static end = IsoDuration.end;
  static toSeconds = IsoDuration.toSeconds;

  readonly end = IsoDuration.end.bind(this);
  readonly toSeconds = IsoDuration.toSeconds.bind(this, this);
  readonly source: string;

  private object: IsoDuration.Duration;
  get durationString() { return this.source; };
  get years() { return this.object.years; };
  get months() { return this.object.months; };
  get weeks() { return this.object.weeks; };
  get days() { return this.object.days; };
  get hours() { return this.object.hours; };
  get minutes() { return this.object.minutes; };
  get seconds() { return this.object.seconds; };

  constructor(durationString: string) {
    this.object = IsoDuration.parse(durationString);
    this.source = durationString;
  }

  toString() {
    return this.source;
  }
  toJSON() {
    return this.source;
  }
}
