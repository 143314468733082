import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import requester, { Request } from './request';
import { Duration } from './models/duration';
import { resultTransformer } from './resultTransformer';
import useOAuth2Token from './oauth2';

export class ApiError extends Error {
  message: string;
  status_code: number;
  constructor(message: string, status_code: number, cause?: unknown) {
    super(message, { cause });
    this.message = message;
    this.status_code = status_code;
  }
}

export const FORMAT = true as const;

type FormatMe = true;

type FormattableTypes = Date | Duration;

type IfFormattable<T, TTrue = true, TFalse = false> = T extends undefined
  ? TFalse
  : T extends undefined | FormattableTypes
  ? TTrue
  : TFalse;

type FormatTree<T> = T extends object
  ? T extends (infer R)[]
    ? CleanArr<R>
    : IfFormattable<T, FormatMe, CleanObj<T>>
  : CleanValue<T>;

type CleanArr<R, THelp = CleanObj<R>> = IfFormattable<
  R,
  readonly [FormatMe],
  R extends object
    ? THelp extends [never]
      ? never
      : readonly [CleanObj<R>]
    : never
>;

type CleanObj<T, TC = { [P in keyof T]-?: FormatTree<T[P]> }> = TC extends {
  [k: string]: never;
}
  ? never
  : Omit<TC, NeverKeys<TC>>;

type CleanValue<T> = IfFormattable<T, FormatMe, never>;

type NeverKeys<T> = {
  [P in keyof T]: T[P] extends never ? P : never;
}[keyof T];

export const getBaseUri = () => {
    // https://developer.mozilla.org/en-US/docs/Web/API/Location
    const parts = window.location.hostname.split('.');
    const endIndex = process.env.REACT_APP_API_DOMAIN ? 1 : -1;
    const hostname = parts.slice(0, endIndex).concat(
        process.env.REACT_APP_API_DOMAIN ? process.env.REACT_APP_API_DOMAIN : `${process.env.REACT_APP_API_TLD}`
    ).join('.');
    const port = process.env.REACT_APP_API_PORT ? `:${process.env.REACT_APP_API_PORT}` : '';
    const proto = `${process.env.REACT_APP_DISABLE_TLS}` === "true" ? 'http' : 'https';

    return `${proto}://${hostname}${port}`;
}

export const getApiUri = () => {
    return `${getBaseUri()}/api/v1`;
}

/** Date and Duration is converted to string */
type Raw<T> = T extends (infer R)[]
  ? RawArray<R>
  : T extends Function
  ? T
  : T extends object
  ? RawObject<RawValue<T>>
  : RawValue<T>;

type RawArray<T> = ReadonlyArray<Raw<T>>;

type RawObject<T> = {
  [P in keyof T]: Raw<T[P]>;
};

type RawValue<T> = T extends Duration | Date
  ? string
  : T extends never // Time
  ? string
  : T;

type ParamValueType = string | number | undefined | FormattableTypes;

export type RequestBodyProperty =
  | RequestBody
  | FormattableTypes
  | string
  | null
  | undefined
  | boolean
  | number;

export type RequestBody = Array<{
    [k: string]: RequestBodyProperty;
  }> | {
  [k: string]: RequestBodyProperty;
};

export type RequestMethod =
  | RequestMethodNeedsBody
  | RequestMethodMaybeBody
  | RequestMethodNoBody;
type RequestMethodNeedsBody = 'POST' | 'PATCH';
type RequestMethodMaybeBody = 'PUT';
type RequestMethodNoBody = 'GET' | 'DELETE';

export type NoId<T> = Omit<T, 'id'>;

export type Minimalize<T extends { id: any }, K extends keyof T> = Partial<T> &
  Pick<T, K> & { id: T['id'] };

export type ApiRequestParams =
  | Readonly<{ [paramName: string]: ParamValueType }>
  | (readonly [name: string, value: ParamValueType])[];

export type ApiRequest<TBody extends RequestBody> = {
  /** Request path. */
  path: string;
  /** api/v1/ will not be added to the path. */
  omitApiPath?: boolean;
  /** Expect this code instead of 200. */
  expectCode?: number;
  /** Request headers. */
  headers?: HeadersInit;
  /** Array or object containing the parameters for the request. */
  params?: ApiRequestParams;
} & (
  | {
      /** Method used for the request */
      method: RequestMethodNeedsBody;
      /** Request body. */
      body: TBody;
    }
  | {
      method: RequestMethodMaybeBody;
      body?: TBody;
    }
  | {
      method?: RequestMethodNoBody;
      body?: never;
    }
);

export type ProcessedApiRequestResult<TExtension> = Readonly<
  {
    [P in keyof TExtension]: P extends keyof Omit<
      UseApiRequestResult<never, never>,
      'result' | 'call'
    >
      ? `${P} is a reserved key!`
      : TExtension[P];
  } & Omit<UseApiRequestResult<never, never>, 'result' | 'call'>
>;

export type ProcessedApiResult<TExtension> = Readonly<
  {
    [P in keyof TExtension]:
      | undefined
      | (P extends keyof Omit<UseApiResult<never>, 'result' | 'call'>
          ? `${P} is a reserved key!`
          : TExtension[P] | undefined);
  } & Omit<UseApiResult<never>, 'result' | 'call'>
>;

export type UseApiResult<TResponse> = Readonly<{
  /** The result of the latest succesful request. Duration and Dates are typed as strings. */
  result: TResponse | undefined;
  /** Error if the request failed. */
  error: ApiError | undefined;
  /** Boolean representing the current state. */
  loading: boolean;
  /** Function to refresh the results. */
  refresh: () => void;
}>;

export type UseApiRequestResult<TResponse, TBody> = Readonly<{
  /**
   * Function to create the request.
   * The hook results will be updated with the newest result from this method.
   * But the result of the request is also returned by this method.
   */
  call: CallFunction<TResponse, TBody>;
}> &
  Omit<UseApiResult<TResponse>, 'refresh'>;

type CallFunction<TResult, TBody> = {
  (pathParam: string | number): Promise<TResult>;
} & TBody extends never
  ? {
      (pathParam?: string | number): Promise<TResult>;
    }
  : {
      (body: TBody): Promise<TResult>;
      (pathParam: string | number, body: TBody): Promise<TResult>;
      (p1: string | number | TBody): Promise<TResult>;
    };

export type UseApiOptions<
  TResult,
  TBody extends RequestBody,
> = ApiRequest<TBody> &
  (TResult extends Raw<TResult>
    ? {
        formatTree?: any; // This is any for performance
      }
    : {
        /** If the result type contains Time or Date objects a formatTree is required for conversion. */
        formatTree: FormatTree<TResult>;
      });

/**
 * Request will be automatically made if using GET method.
 * @param options Object containing options:
 * - `path` Request path.
 * - `params` Array or object containing the parameters for the request.
 * - `headers` Request headers.
 * - `method` Method used for the request
 * - `body` Request body.
 * - `formatTree` Object defining keys that need to be converted from string to Date or Duration.
 *                The type for this will be automatically inferred from result type.
 * @returns An object containing:
 * - `result` The result of the latest succesful request.
 * - `error` Error if the request failed.
 * - `loading` Boolean representing the current state.
 * - `refresh` Function to refresh the results.
 */
export function useApi<TResult, TBody extends RequestBody = never>(
  options: UseApiOptions<TResult, TBody>,
): UseApiResult<TResult> {
  const { error, loading, result, call } = useApiRequest<TResult, TBody>(
    options,
  );

  useEffect(() => {
    (call as (...params: any) => Promise<TResult>)(options.body).catch(e => {});
  }, [options.body, call]);

  return useMemo(
    () => ({
      result,
      error,
      loading,
      refresh: (call as any).bind(null, options.body),
    }),
    [error, loading, options.body, result, call],
  );
}

/**
 * Provides a function to make an API request.
 * @param options Object containing options:
 * - `path` Request path.
 * - `omitApiPath` Omit api path?.
 * - `params` Array or object containing the parameters for the request.
 * - `headers` Request headers.
 * - `method` Method used for the request
 * - `formatTree` Object defining keys that need to be converted from string to Date or Duration.
 *                The type for this will be automatically inferred from result type.
 * @returns An object containing:
 * - `result` The result of the latest succesful request.
 * - `error` Error if the request failed.
 * - `loading` Boolean representing the current state.
 * - `refresh` Function to refresh the results.
 */
export function useApiRequest<TResult, TBody extends RequestBody = never>({
  path,
  headers,
  expectCode = 200,
  method = 'GET',
  params,
  formatTree,
}: Omit<UseApiOptions<TResult, TBody>, 'body'>): UseApiRequestResult<
  TResult,
  TBody
> {
  const [result, setResult] = useState<IdValue<TResult>>(emptyValue);
  const [error, setError] = useState<IdValue<ApiError>>(emptyValue);
  const [loading, setLoading] = useState(false);
  const [token, ] = useOAuth2Token();
  const nextId = useRef(0);

  const call = useCallback(
    (p1: string | number | TBody, p2?: TBody) => {
      const isOverload = typeof p1 === 'string' || typeof p1 === 'number';
      const pathParam = (isOverload ? p1 : undefined) as number | string;
      const body = (isOverload ? p2 : p1) as TBody;
      return new Promise<TResult>((resolve, reject) => {

        const requestId = nextId.current++;
        setLoading(true);

        const paramString = makeParamString(params);

        let uri = `${getApiUri()}/${path}${paramString}`;
        if (pathParam !== undefined) {
          if (!uri.includes('{0}')) {
            throw Error('Uri did not contain a {0} when pathParam was used.');
          }
          uri = uri.replaceAll('{0}', pathParam.toString());
        }

        makeRequest<TResult, TBody>(
          {
            uri,
            token,
            method,
            headers,
            expectCode,
            body,
            formatTree,
          },
          res => {
            setResult(v => updateIdValue(v, requestId, res));
            setError(v => updateIdValue(v, requestId, undefined));
            if (requestId === nextId.current - 1) {
              setLoading(false);
            }
            resolve(res);
          },
          err => {
            // Result is not cleared in the case of an error
            setError(v => updateIdValue(v, requestId, err));
            if (requestId === nextId.current - 1) {
              setLoading(false);
            }
            reject(err);
          },
        );
      }) as any;
    },
    [
      expectCode,
      token,
      params,
      path,
      method,
      headers,
      formatTree,
    ],
  );

  return useMemo(
    () => ({
      result: result.value,
      error: error.value,
      loading,
      call: call as any,
    }),
    [result, error, loading, call],
  );
}

type MakeRequestOptions<TResult, TBody extends RequestBody = never> = {
  uri: string;
  token: string;
  expectCode: number;
  method: string;
  headers: HeadersInit | undefined;
  body: TBody | undefined;
  formatTree: UseApiOptions<TResult, TBody>['formatTree'] | undefined;
};

export function makeParamString(params: ApiRequestParams | undefined) {
  if (params) {
    const urlParams = new URLSearchParams();
    const entries = Array.isArray(params) ? params : Object.entries(params);
    for (const [name, value] of entries) {
      if (value === undefined) {
        urlParams.append(name, '');
      } else if (value instanceof Date) {
        // The API is using strtime instead of ISO time due to a mistake
        urlParams.append(name, strftime(value));
      } else if (value instanceof Duration) {
        urlParams.append(name, value.toJSON());
      } else urlParams.append(name, value as any);
    }
    return `?${urlParams}`;
  }
  return '';
}

function makeRequest<TResult, TBody extends RequestBody = never>(
  {
    uri,
    token,
    method,
    headers,
    expectCode,
    body,
    formatTree,
  }: MakeRequestOptions<TResult, TBody>,
  onResult: (value: TResult) => void,
  onError: (value: ApiError) => void,
) {
  const req: Request = {
    uri,
    method,
    headers: {
      ...headers,
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json;charset=UTF-8',
    },
    body: body ? JSON.stringify(body) : undefined,
  };

  log(`${method} ${uri}`);

  requester(req)
    .then(async response => {
      if (response.status === expectCode) {
        const json: Raw<TResult> = await response.json();
        const result = formatTree
          ? resultTransformer<TResult>(json, formatTree)
          : (json as TResult);
        onResult(result);
      } else {
        const error = new ApiError(
          `Request failed: ${response.status}: (${await response.text()})`,
          response.status,
        );
        log(error.message);
        onError(error);
      }
    })
    .catch(err => {
      onError(new ApiError(err.message, err.status_code, err));
    });
}

type IdValue<T> = Readonly<{
  id: number;
  value: T | undefined;
}>;

function updateIdValue<T>(old: IdValue<T>, id: number, value: T) {
  if (old.id > id) {
    return old;
  } else {
    return { id, value };
  }
}

const emptyValue = {
  id: -1,
  value: undefined,
};

function pad(s: any, length: number = 2) {
  return s.toString().padStart(length, '0');
}

function strftime(date: Date): string {
  const hours = Math.floor(-date.getTimezoneOffset() / 60);
  const minutes = -date.getTimezoneOffset() % 60;
  const oper = date.getTimezoneOffset() <= 0 ? '+' : '-';

  return (
    '' +
    // YYYY-MM-DD
    `${date.getFullYear()}-${pad(date.getMonth() + 1)}-${pad(date.getDate())}` +
    ' ' +
    // HH-MM-SS.nnn
    `${pad(date.getHours())}:${pad(date.getMinutes())}:${pad(
      date.getSeconds(),
    )}.${pad(date.getMilliseconds(), 3)}` +
    // +0200
    `${oper}${pad(hours)}${pad(minutes)}`
  );
}

function log(message: string) {
  console.log(`\x1b[33m[API]\x1b[0m ${message}`);
}
