import React, { useState } from "react";
import { TableSidebarProps } from "./sidebar-components/table-sidebar";
import { Message } from "../api/models/messages";
import { useCreateMessage } from "../api/apis/messages";
import { useGetUserMessages } from "../api/apis/personal/messages";
import * as Styled from "../components/sidebar-components/table-sidebar.styled";
const CreateAnnouncements = (props: TableSidebarProps<Message>) => {
	// const {
	// 	items,
	// 	inputs,
	// 	title,
	// 	submitText,
	// 	submiterType,
	// 	createItem,
	// 	onFinish,
	// } = props;
	const { messages, refresh } = useGetUserMessages(0, 1000);
	const { createMessage, loading } = useCreateMessage();

	const [currentAnnouncement, setCurrentAnnoucement] = useState("");

	const formatDateTime = (dateTime: Date): string => {
		const date = new Date(dateTime);
		const formattedDate = `${date.getDate()}/${
			date.getMonth() + 1
		}/${date.getFullYear()}`;
		const formattedTime = `${date.getHours()}:${date.getMinutes()}`;
		return `${formattedDate}, ${formattedTime}`;
	};

	const handleInputChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
		setCurrentAnnoucement(e.target.value);
	};

	const clickHandler = async () => {
		if (loading) {
			return;
		}

		const newAnnoucement = {
			content: currentAnnouncement,
			target_type: "group",
			target_id: "1",
			expire_previous: false,
		};
		await createMessage(newAnnoucement);

		refresh();
		setCurrentAnnoucement("");
	};

	return (
		<Styled.Body>
			<Styled.Title>CREATE</Styled.Title>
			{messages && messages.length > 0 && (
				<>
					<Styled.ItemText>UserName</Styled.ItemText>
					<Styled.ItemValue
						value={messages[0].sender.username}
						disabled
						name="username"
					/>

					<Styled.ItemText>From</Styled.ItemText>
					<Styled.ItemValue
						value={`${messages[0].sender.first_name} ${
							messages![0].sender.last_name
						}`}
						disabled
						name="fullname"
					/>

					<Styled.ItemText>Date and time</Styled.ItemText>
					<Styled.ItemValue
						value={formatDateTime(messages[0].created_at)}
						disabled
						name="date"
					/>

					<Styled.ItemText>Announcement</Styled.ItemText>
					<Styled.Textarea
						name="text"
						value={currentAnnouncement}
						onChange={handleInputChange}
						placeholder="Write your annoucement here"
					></Styled.Textarea>

					<Styled.Button
						onClick={clickHandler}
						disabled={loading}
					>
						Create annoucement
					</Styled.Button>
				</>
			)}
		</Styled.Body>
	);
};
export default CreateAnnouncements;
