import React, { useEffect } from 'react'
import Loader from './help-components/loader'

import { User } from '../api/models/user'

import { Table } from '../components/table'
import { Box } from './general-purpose'

import { ColumnDef } from '@tanstack/react-table'
import Checkbox from './help-components/checkbox'
import { useCreateUser, useListUsers, useUpdateUser } from '../api/apis/user'
import { useSidebarState } from './sidebar'

const isSidebarOpenWrapper = {isOpen: false}

export default function GetUsers() {
    const {users, error, loading, refresh} = useListUsers(0, 1000);
    const [data, setData] = React.useState<User[]>([]);
    const {createUser} = useCreateUser();
    const {updateUser} = useUpdateUser();
    
    const sidebarUpdate =(results: (User | undefined)[]) => {
        console.log("parent");
        results.forEach((user) => {
            console.log(user?.id);
          });
 
 
 setData(data.map(item => results.find(results => results?.id === item.id) ?? item))}  

    const columns = React.useMemo<ColumnDef<User>[]>(
        () => [
            {
                id: 'select',
                header: ({table}) => (
                    <Checkbox
                        {...{
                            checked: table.getIsAllRowsSelected(),
                            disabled: !table.options.enableRowSelection,
                            indeterminate: table.getIsSomeRowsSelected(),
                            onChange: table.getToggleAllRowsSelectedHandler()
                        }}
                    />
                ),
                cell: ({row}) => (
                    <Checkbox
                        {...{
                            checked: row.getIsSelected(),
                            disabled: !row.getCanSelect(),
                            indeterminate: row.getIsSomeSelected(),
                            onChange: row.getToggleSelectedHandler()
                        }}
                    />
                ),
            },
            {
                accessorKey: 'username',
                header: 'Username',
                cell: info => info.getValue()
            },
            {
                accessorKey: 'first_name',
                header: 'First name',
                cell: info => info.getValue()
            },
            {
                accessorKey: 'last_name',
                header: 'Last name',
                cell: info => info.getValue()
            }, 
            {
                accessorKey: 'direction',
                header: 'Tag',
                cell: info => info.getValue()
            },
            {
                accessorKey: 'email',
                header: 'Email',
                cell: info => info.getValue()
            },            
            {
                accessorKey: 'phone_number',
                header: 'Phone number',
                cell: info => info.getValue()
            }
        ], []
    )
    const { isOpen } = useSidebarState();
    
    isSidebarOpenWrapper.isOpen = isOpen;

    useEffect(() => {
        users && setData(users)
    }, [users])

    useEffect(() => {
        const refreshTIme = 120000;
        const interval = setInterval(refresh, refreshTIme);
        return () => clearInterval(interval);
    }, [refresh]); 

    if(error) {
        return (
            <>
                <p>Error occurred: {JSON.stringify(error)}</p>
                <button onClick={refresh}>Try again</button>
            </>
        )
    }

    function createItem(item: Partial<User>): Promise<User> {
        return createUser({
            username: '',
            first_name: '',
            last_name: '',
            ...item,
        })
    }

    function updateItem(old: User, item: Partial<User>): Promise<User> {
        return updateUser({
            ...old,
            ...item,
        })
    }

    return (
        <Box css={{height: '100vh', overflowY: 'scroll'}}>
            {loading
                ? <Loader />
                : <Table<User>
                    data={data}
                    columns={columns}
                    sidebarInputs={[
                        { key: 'username', label: 'Username', noMultiEdit: true, fieldType: "text"},
                        { key: 'first_name', label: 'First name', noMultiEdit: true, fieldType: "text"},
                        { key: 'last_name', label: 'Last name', noMultiEdit: true, fieldType: "text"},
                        // { key: 'tag', label: 'User'}, // Not present in api
                        { key: 'email', label: 'Email', type: 'email', noMultiEdit: true, fieldType: "text"},
                        { key: 'phone_number', label: 'Phone number', type: 'tel', noMultiEdit: true, fieldType: "text"} ,
                        { key: 'group', label: 'Group', fieldType: "text"}, // Not present in api
                    ]}
                    createItem={createItem}
                    updateItem={updateItem}
                    sidebarUpdateProp={sidebarUpdate}
                />
            }
        </Box>
    )  
}
