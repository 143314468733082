import * as Styled from './login.styled'
import svep_logo from '../Assets/Images/svep-logo.png'

import { Outlet } from 'react-router'
import { Box, Text } from './general-purpose'

import { useNavigate } from 'react-router'

export const PageLayout = () => {
    return (
        <Styled.Container>
            <Styled.LoginBox>
                <Box css={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    placeSelf: 'stretch',
                    justifyContent: 'space-between'
                }}>
                    <Outlet />
                </Box>
            </Styled.LoginBox>
        </Styled.Container>
    )
}

export const LoginPage = () => {
    const navigate = useNavigate()

    return (
        <>
            <Box css={{
                display: 'flex',
                flexDirection: 'column',
                color: 'grey', 
                fontSize: '18px',
                marginBottom: '18px'
            }}>
                <Styled.LoginLogo src={svep_logo} alt='svep' />
                <Text>Login to access Dashboard</Text>
            </Box>

            <Box css={{
                display: 'flex',
                flexDirection: 'column',
                
                'input': {
                    marginBottom: '16px'
                }
            }}>
                <Styled.InputField placeholder='Username' />
                <Styled.InputField placeholder='Password' />
            </Box>

            <Box css={{
                width: '90%', 
                display: 'flex', 
                flexDirection: 'row', 
                justifyContent: 'space-between',
                color: 'grey',
                marginBottom: '16px',

                '*': {
                    cursor: 'pointer'
                }
            }}>
                <Styled.Checkbox />

                <Styled.LoginText 
                    onClick={() => navigate('../forgot-password')}
                >
                    Forgot password?
                </Styled.LoginText>
            </Box>

            <Styled.LoginButton 
                onClick={() => navigate('../../dashboard')}
                css={{marginBottom: '68px'}} 
            >
                Sign In
            </Styled.LoginButton>
        </>
    )
}

export const ForgotPasswordPage = () => {
    const navigate = useNavigate()

    return (
        <>
            <Styled.LoginLogo src={svep_logo} alt='svep' />

            <Box css={{
                display: 'flex',
                flexDirection: 'column',
                alignSelf: 'baseline',
                justifyContent: 'space-between',
                color: 'grey',
                height: '92px',
                fontSize: '18px',
                margin: '0 34px 38px 34px'
            }}>
                { /* text below should be fetched from somewhere later on and displayed according to current language */ }

                <Text>Forgot password?</Text>
                <Text>Please enter the email address you'd like your password information sent to</Text>
            </Box>

            <Box css={{
                display: 'flex',
                height: '162px',
                alignItems: 'center',
                flexDirection: 'column',
                justifyContent: 'space-between',
                marginBottom: '96px'
            }}>
                <Styled.InputField placeholder='john.doe@email.com' />

                <Styled.LoginButton 
                    onClick={() => navigate('../reset-password')}
                >
                    Request reset link
                </Styled.LoginButton>
            </Box>
        </>
    )
}

export const ResetPasswordPage = () => {
    const navigate = useNavigate()

    return (
        <>
            <Styled.LoginLogo src={svep_logo} alt='svep' />

            <Box css={{
                display: 'flex',
                flexDirection: 'column',
                marginBottom: '42px',
                fontSize: '19px',
                color: 'grey',
                
                '*': {
                    marginBottom: '16px'
                }
            }}>
                <Text>Set new password</Text>

                <Styled.InputField placeholder='New password' />
                <Styled.InputField placeholder='Confirm new password' />
            </Box>

            <Styled.LoginButton 
                onClick={() => navigate('../login')} 
                css={{marginBottom: '84px'}}
            >
                Reset password
            </Styled.LoginButton>
        </>
    )
}
