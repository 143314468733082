import React, { useState, useEffect, MouseEvent  } from 'react'
import { ImCheckmark } from 'react-icons/im';
import {FaRegEdit} from 'react-icons/fa'
import {TbTrashX} from 'react-icons/tb'
import * as Icons from '@radix-ui/react-icons'
import { Tag } from "../../api/models/tag";
import { Activity } from "../../api/models/activity";

import { TableSidebarProps } from './table-sidebar'
import { TextAreaStyles, TableHeaderStyles, AddActivities } from './tags-sidebar.styled'
import { useGetTag } from '../../api/apis/tag'


export function TagActivitiesSidebar(props: TableSidebarProps<Tag>) {
    const {tag} = useGetTag(props.items[0].id);
    const [tags, setTags] = useState<TagsState>({ activities: [] });
    interface TagsState {
        activities: Activity[];
    }

    const [showAddForm, setShowAddForm] = useState(false);

    const handleAddActivityClick = () => {
        setShowAddForm((prevShowAddForm) => !prevShowAddForm);
    };
    
    useEffect(() => {
        console.log(tags.activities);
    }, [tags.activities]);

    const handleActivityCreation = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault(); // Prevent form submission from refreshing the page

        const formData = new FormData(event.currentTarget);

        // Create an Activity object with the form data
        const activity = {
            name: formData.get("name") as string,
            direction: formData.get("direction") as string,
            location: formData.get("location") as string,
        };

        // Add the Activity to the tags array
        setTags((prevTags: { activities: any }) => ({
            ...prevTags,
            activities: [...prevTags.activities, activity],
        }));

        // Reset the form
        // event.currentTarget.reset();
        setShowAddForm(false);
        console.log(tags.activities)
    };
    return (
        <span>
            <AddActivities>

                Add activity
                <button onClick={handleAddActivityClick}> <Icons.PlusIcon width='20' height='20' viewBox='2 1 12 12' />
                </button>
            </AddActivities>
            {showAddForm && (
                <form onSubmit={handleActivityCreation}>
                    <label>
                        Description
                        <input type="text" name="name" />

                    </label>
                    <label>
                        Direction
                        <select name="direction" id="direction">
                            <option value="In" > In</option>
                            <option value="Out" >Out</option>
                            <option value="Both" > Both</option>

                        </select>
                    </label>
                    <label>
                        Location
                        <input type="text" name="location" />
                    </label>
                    <input type="submit" value="Submit" />
                </form>)}

            <table style={{ borderCollapse: 'separate', borderSpacing: '0px 4px' }}>
                <tr>
                    <th style={{ ...TableHeaderStyles.mainContainer, ...TableHeaderStyles.descriptionContainer }}>Description</th>
                    <th style={{ ...TableHeaderStyles.mainContainer, ...TableHeaderStyles.locationContainer }}>Location</th>
                    <th style={{ ...TableHeaderStyles.mainContainer, ...TableHeaderStyles.inContainer }}>In</th>
                    <th style={{ ...TableHeaderStyles.mainContainer, ...TableHeaderStyles.outContainer }}>Out</th>
                    <th style={{ ...TableHeaderStyles.mainContainer, ...TableHeaderStyles.emptyContainer }}></th>

                </tr>
                {tag?.activities.map((activity: { id: number; name: string; location: string; direction: any }) =>
                    <TagActivity
                        id={activity.id}
                        enabled={true}
                        name={activity.name}
                        location={activity.location}
                        direction={activity.direction}
                    />)}
            </table>
        </span>)
}

function TagActivity({ id, name, location, direction }: Activity) {
    const [showEditForm, setShowEditForm] = useState(false);
    const handleMouseEnter = (event: React.MouseEvent<HTMLTableRowElement>) => {
        const target = event.target as HTMLTableRowElement;
        target.style.background = '#000000';
    };
    const handleMouseLeave = (event: React.MouseEvent<HTMLTableRowElement>) => {
       const target = event.target as HTMLTableRowElement;
       target.style.background = '#EFEFEF';
    };
    
    function handleEditActivityClick(event: MouseEvent<HTMLButtonElement>): void {
        setShowEditForm((prevShowEditForm) => !prevShowEditForm);

    }

if(showEditForm === false){
    return (
        <tr onMouseOver={handleMouseEnter} onMouseOut={handleMouseLeave}>
        
            <td style={{ ...TextAreaStyles.mainContainer, ...TextAreaStyles.descriptionContainer }}>{name || name ? name + ' ' : 'Name is empty'}</td>
            <td style={{ ...TextAreaStyles.mainContainer, ...TextAreaStyles.locationContainer }}>{location ? location + ' ' : 'Location is empty'}</td>
            <td style={{ ...TextAreaStyles.mainContainer, ...TextAreaStyles.inContainer }}>{direction === null || direction === 'out' ? <div style={TextAreaStyles.uncheckedCheckmarkContainer}></div> : ''} {direction === 'in' ? <div style={TextAreaStyles.checkmarkContainer}><ImCheckmark style={TextAreaStyles.checkmark} /></div> : ''}{direction === 'both' ? (
                <div style={TextAreaStyles.checkmarkContainer}><ImCheckmark style={TextAreaStyles.checkmark} /></div>
            ) : ''}</td>

            <td style={{ ...TextAreaStyles.mainContainer, ...TextAreaStyles.outContainer }}>{direction === null || direction === 'in' ? <div style={TextAreaStyles.uncheckedCheckmarkContainer}></div> : ''} {direction === 'out' ? <div style={TextAreaStyles.checkmarkContainer}><ImCheckmark style={TextAreaStyles.checkmark} /></div> : ''}{direction === 'both' ? (
                <div style={TextAreaStyles.checkmarkContainer}><ImCheckmark style={TextAreaStyles.checkmark} /></div>
            ) : ''}</td>
            <td style={{ ...TextAreaStyles.mainContainer, ...TextAreaStyles.editButton }} >
            <span>
            <button onClick={handleEditActivityClick} style={{...TextAreaStyles.editButtonPos}} >   <FaRegEdit />
            </button>
          
            </span>
            </td>
        </tr>

    );


    }
    else{
        return (
            <tr>
          
                <td style={{...TextAreaStyles.mainContainer, ...TextAreaStyles.descriptionContainer}}>
                    <textarea name="Description" defaultValue={name} style={{...TextAreaStyles.editTextBox, resize:'none'}}/>  
                </td>
                <td style={{...TextAreaStyles.mainContainer, ...TextAreaStyles.locationContainer}}>
                    <textarea name="Location" defaultValue={location} style={{...TextAreaStyles.editTextBox, resize:'none'}}/>  
                </td>
                <td style={{...TextAreaStyles.mainContainer, ...TextAreaStyles.inContainer}}>
                    <input type="checkbox" id="In" name="In" value="In" defaultChecked={direction !== 'out'}>            
                    </input>
                </td> 
                <td style={{...TextAreaStyles.mainContainer, ...TextAreaStyles.outContainer}}>
                    <input type="checkbox" id="Out" name="Out" value="Out" defaultChecked={direction !== 'in'}>
                    </input> 
                </td>
                <td style={{...TextAreaStyles.mainContainer, ...TextAreaStyles.editButton}}>
                    <button onClick={handleEditActivityClick} style={{...TextAreaStyles.trashButtonPos}}> <TbTrashX />
                    </button>
                </td>
            </tr>
        ) 
    }
}

