import { FORMAT } from './api';
import { Duration } from './models/duration';

/**
 * The result transformer modifies the response object deeply based on the `tree` parameter. The response object
 * @param response
 * @param tree
 * @returns
 */
export function resultTransformer<T>(response: any, tree: {} | []): T {
  const isArray = Array.isArray(tree);
  if (response) {
    for (const [treeKey, branch] of Object.entries(tree)) {
      if (isArray) {
        transformArray(response, treeKey, branch);
      } else {
        transformKey(response, treeKey, branch);
      }
    }
  }
  return response as T;
}

function transformArray(response: any, treeKey: string, branch: never) {
  for (let i = 0; i < response.length; i++) {
      const item = (response as (string | undefined)[])[i];
    if (!item) continue;
    if (branch === FORMAT) {
      response[i] = formatString(item);
    } else {
      response[i] = resultTransformer(response[i], branch);
    }
  }
}

function transformKey(response: any, treeKey: string, branch: never) {
  if (!response[treeKey]) return;
  if (branch === FORMAT) {
    if (typeof response[treeKey] !== 'string') return;
    response[treeKey] = formatString(response[treeKey]);
  } else {
    response[treeKey] = resultTransformer(response[treeKey], branch);
  }
}

function formatString(isoString: string) {
  if (isoString.match(Duration.pattern)) {
    return new Duration(isoString);
  } else {
    return new Date(isoString);
  }
}
