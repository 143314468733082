import * as Icons from '@radix-ui/react-icons'
import React, { ReactNode, createContext, useState, useContext, useRef, MutableRefObject, PropsWithChildren } from 'react';
import * as Styled from './sidebar.styled';
import { Box } from './general-purpose';


/**
 * Options used to customize the sidebar functions
 */
export type SidebarOptions = {
    /** Will the close button be hidden? */
    hideCloseButton?: boolean,
    /** Function called when the X button is closed. If false is returned, the sidebar will not be closed. */
    onCloseButton?: (hasChanges: boolean) => boolean | undefined
}

/**
 * Provides all sidebar state. Your component will rerender when this state changes.
 */
export function useSidebarState(): SidebarContextType {
    return useContext(SidebarStateContext)
}

/**
 * You can use these refs to use the sidebar context without having your
 * component update each time the sidebar is updated by another component
 */
export function useSidebarRef(): SidebarRefContextType['current'] {
    return useContext(SidebarRefContext).current
}

/**
 * Provides sidebar utility functions.
 */
export function useSidebar(): UseSidebar {
    const refContext = useContext(SidebarRefContext);

    return {
        setSidebar: ((arg1: any, arg2?: any, arg3?: any, arg4?: any) => {
            refContext.current.setSidebar(arg1, arg2, arg3, arg4)
        }) as UseSidebar['setSidebar'],
        toggleSidebar: ((tag: any, content?: JSX.Element | null, options?: SidebarOptions) => {
            refContext.current.toggleSidebar(tag, content, options)
        }),
    };
}

/**
 * Provider to allow use of the sidebar.
 * Add a Sidebar component as a descendant.
 */
export const SidebarProvider = ({ children }: { children: ReactNode }) => {
    return (
        <SidebarRefContextProvider>
            <SidebarStateProvider>
                {children}
            </SidebarStateProvider>
        </SidebarRefContextProvider>
    );
};

/**
 * Sidebar component that is used by SidebarProvider.
 */
export function Sidebar() {
    const { setSidebar, content, isOpen, options, hasChanges } = useSidebarState();

    return (
        <Styled.Sidebar state={isOpen ? 'visible' : undefined}>
            {(!options || !options.hideCloseButton) &&
                <Styled.CloseButton onClick={() => {
                    if (options?.onCloseButton) {
                        if (options.onCloseButton(hasChanges) !== false) {
                            setSidebar(false)
                        }
                    } else {
                        setSidebar(false)
                    }
                }}>
                    <Icons.Cross2Icon width='20' height='20' viewBox='2 1 12 12' />
                </Styled.CloseButton>
            }
            <Box style={{ padding: '12px', height: 'calc(100% - 38px)' }}>
                {content}
            </Box>
        </Styled.Sidebar>
    )

}


// STATE CONTEXT TYPE

type UseSidebar = {
    setSidebar: {
        (show: false): void;
        (show: true, tag: any, content: JSX.Element, options?: SidebarOptions): void;
        (show: boolean, tag: any, content: JSX.Element, options?: SidebarOptions): void;
        (content: JSX.Element, tag?: any, options?: SidebarOptions): void;
    }
    toggleSidebar: {
        (tag: any, content?: JSX.Element | null, options?: SidebarOptions): void;
    }
}

type SidebarContextType = UseSidebar & {
    /** Use tag to create logic, like hiding the sidebar when clicking a button again. If tag is falsy, it will not be used */
    tag: any;
    isOpen: boolean;
    hasChanges: boolean;
    content: JSX.Element | null;
    options?: SidebarOptions,
    setTag(value: any): void
    setIsOpen(value: boolean): void
    setHasChanges(value: boolean): void
    setContent(value: JSX.Element): void
    setOptions(value: SidebarOptions): void
};


// REF CONTEXT

type SidebarRefContextType = MutableRefObject<SidebarContextType> 

const SidebarRefContext = createContext<SidebarRefContextType>(null as any);

function SidebarRefContextProvider({children}: PropsWithChildren) {
    const ref = useRef<SidebarRefContextType['current']>({} as any);
    return (
        <SidebarRefContext.Provider value={ref}>
            {children}
        </SidebarRefContext.Provider>
    )
}


// SIDEBAR STATE CONTEXT

const SidebarStateContext = createContext<SidebarContextType>(null as any);

function SidebarStateProvider({ children }: PropsWithChildren) {const [ tag, setTag ] = useState<any>(null);
    const refContext = useContext(SidebarRefContext);
    const [ isOpen, setIsOpen ] = useState(false);
    const [ hasChanges, setHasChanges ] = useState(false);
    const [ content, setContent ] = useState<JSX.Element | null>(null);
    const [ options, setOptions ] = useState<SidebarOptions>();

    const setSidebar: UseSidebar['setSidebar'] = ((p1, tag?, p3?, p4?) => {
        const contentFirst = typeof (p1) !== 'boolean';
        const show = contentFirst ? true : p1;
        const content = (contentFirst ? p1 : p3) as JSX.Element;
        const options = (contentFirst ? p3 : p4) as SidebarOptions;

        if (show) {
            setIsOpen(true)
            setTag(tag)
            setContent(content)
            setHasChanges(false)
            setOptions(options)
        } else {
            setIsOpen(false)
            setTag(undefined)
            setHasChanges(false)
            setOptions(options)
        }

    })

    const toggleSidebar: UseSidebar['toggleSidebar'] = ((newTag, content?, options?) => {
        if (tag !== newTag) {
            setIsOpen(true)
            setTag(newTag)
            if (content !== undefined) {
                setContent(content)
            }
            setHasChanges(false)
            setOptions(options)
        } else {
            setIsOpen(false)
            setTag(undefined)
            setHasChanges(false)
        }
    })

    const value = {
        content: refContext.current.content = content,
        hasChanges: refContext.current.hasChanges = hasChanges,
        isOpen: refContext.current.isOpen = isOpen,
        tag: refContext.current.tag = tag,
        options: refContext.current.options = options,
        setTag: refContext.current.setTag = setTag,
        setIsOpen: refContext.current.setIsOpen = setIsOpen,
        setHasChanges: refContext.current.setHasChanges = setHasChanges,
        setContent: refContext.current.setContent = setContent,
        setOptions: refContext.current.setOptions = setOptions,
        setSidebar: refContext.current.setSidebar = setSidebar,
        toggleSidebar: refContext.current.toggleSidebar = toggleSidebar,
    }

    return (<SidebarStateContext.Provider value={value}>
        {children}
    </SidebarStateContext.Provider>)
}
