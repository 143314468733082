import React, { useState } from "react";
import Loader from "./help-components/loader";
import { Message, MessageTemplate } from "../api/models/messages";
import { Table } from "../components/table";
import { Box } from "./general-purpose";
import Checkbox from "./help-components/checkbox";
import { useCreateMessage, useExpireMessage } from "../api/apis/messages";

import { ColumnDef } from "@tanstack/react-table";
import * as TableStyled from "./sidebar-components/table-sidebar.styled";

import { useGetUserMessages } from "../api/apis/personal/messages";
import CreateAnnouncements from "./create-announcements";

type TruncatedTextProps = {
	value: string;
};

const TruncatedText: React.FC<TruncatedTextProps> = ({ value }) => {
	const [readMore, setReadMore] = useState(false);

	return (
		<div
			style={{
				// whiteSpace: "nowrap",
				overflow: "hidden",
				// textOverflow: "ellipsis",
			}}
		>
			{readMore ? value : `${value.substring(0, 20)}`}
			{value.length > 20 && (
				<>
					{!readMore}
					<button
						onClick={() => setReadMore(!readMore)}
						style={{
							border: "none",
							color: "blue",
							backgroundColor: "transparent",
						}}
					>
						{readMore ? "Show Less" : "... Show More"}
					</button>
				</>
			)}
		</div>
	);
};

const formatDateTime = (dateTime: Date): string => {
	const date = new Date(dateTime);
	const formattedDate = `${date.getDate()}/${
		date.getMonth() + 1
	}/${date.getFullYear()}`;
	const formattedTime = `${date.getHours()}:${date.getMinutes()}`;
	return `${formattedDate}, ${formattedTime}`;
};

export default function Announcements() {
	// const message: UseListMessagesParams = {
	// 	target_type: "group",
	// 	target_id: 1,
	// 	page: 0,
	// 	limit: 1000,
	// };
	// const { messages, error, loading, refresh } = useListMessages(message);
	const { messages, error, loading, refresh } = useGetUserMessages(0, 1000);
	const [annoucement, setAnnouncement] = useState<Message[]>([]);
	const { createMessage } = useCreateMessage();
	const { expireMessage } = useExpireMessage();

	const sidebarUpdate = (results: (Message | undefined)[]) => {
		console.log("parent");
		results.forEach((message) => {
			console.log(message?.id);
		});

		setAnnouncement(
			annoucement.map(
				(item) =>
					results.find((results) => results?.sender.id === item.sender.id) ??
					item
			)
		);
	};

	const columns = React.useMemo<ColumnDef<Message>[]>(
		() => [
			{
				id: "select",
				header: ({ table }) => (
					<Checkbox
						{...{
							checked: table.getIsAllRowsSelected(),
							disabled: !table.options.enableRowSelection,
							indeterminate: table.getIsSomeRowsSelected(),
							onChange: table.getToggleAllRowsSelectedHandler(),
						}}
					/>
				),
				cell: ({ row }) => (
					<Checkbox
						{...{
							checked: row.getIsSelected(),
							disabled: !row.getCanSelect(),
							indeterminate: row.getIsSomeSelected(),
							onChange: row.getToggleSelectedHandler(),
						}}
					/>
				),
			},
			{
				id: "recipient",
				accessorKey: "sender",
				header: "Username",
				cell: (info) => info.row.original.sender.username,
			},
			{
				id: "sender",
				accessorKey: "sender",
				header: "From",
				cell: (info) =>
					`${info.row.original.sender.first_name} ${info.row.original.sender.last_name}`,
			},
			{
				id: "created_at",
				accessorKey: "created_at",
				header: "Date and time",
				cell: (info) => formatDateTime(info.row.original.created_at),
			},
			{
				id: "content",
				accessorKey: "content",
				header: "Announcement",
				cell: (info) => <TruncatedText value={`${info.getValue()}`} />,
			},
		],
		[]
	);

	React.useEffect(() => {
		messages && setAnnouncement(messages);
	}, [messages]);

	if (error) {
		return (
			<>
				<p>Error occurred: {JSON.stringify(error)}</p>
				<button onClick={refresh}>Try again</button>
			</>
		);
	}

	const createItem = (newAnnouncement: Partial<Message>): Promise<Message> => {
		const template: MessageTemplate = {
			content: newAnnouncement.content as string,
			target_type: "group",
			target_id: "1",
			expire_previous: true,
		};
		return createMessage(template);
	};

	const updateItem = async (
		oldAnnouncement: Message,
		updatedAnnouncement: Partial<Message>
	): Promise<Message> => {
		const updatedMessage = await expireMessage(oldAnnouncement.id);
		refresh();
		return { ...oldAnnouncement, ...updatedMessage };
	};

	return (
		<Box css={{ height: "100vh", overflowY: "scroll" }}>
			{loading ? (
				<Loader />
			) : (
				<Table<Message>
					singleSelect
					data={annoucement}
					columns={columns}
					largeAddButton
					largeAddButtonText="New Announcement"
					customSidebar={CreateAnnouncements}
					sidebarInputs={[
						{
							key: "sender",
							label: "UserName", // Label is overwritten by custom fieldType
							noMultiEdit: true,
							fieldType: "custom",
							customComponent: ({
								changeValue,
								multi,
								inactive,
								mismatch,
								options,
								state,
							}) => {
								const inactiveState = inactive ? "inactive" : undefined;

								const sender = (state.current ?? {}) as Message["sender"];

								return (
									<>
										<TableStyled.Item key={options.label}>
											<TableStyled.ItemText state={inactiveState}>
												Username:
											</TableStyled.ItemText>
											<TableStyled.ItemValue
												spellCheck={false}
												disabled
												state={inactiveState}
												value={sender.username ?? ""}
												type={options.type ?? "text"}
											/>
										</TableStyled.Item>
									</>
								);
							},
						},
						{
							key: "sender",
							label: "FullName", // Label is overwritten by custom fieldType
							noMultiEdit: true,
							fieldType: "custom",
							customComponent: ({
								changeValue,
								multi,
								inactive,
								mismatch,
								options,
								state,
							}) => {
								const inactiveState = inactive ? "inactive" : undefined;

								const sender = (state.current ?? {}) as Message["sender"];

								return (
									<>
										<TableStyled.Item key={options.label}>
											<TableStyled.ItemText state={inactiveState}>
												From:
											</TableStyled.ItemText>
											<TableStyled.ItemValue
												spellCheck={false}
												disabled
												state={inactiveState}
												value={`${sender.first_name} ${sender.last_name}`}
												type={options.type ?? "text"}
											/>
										</TableStyled.Item>
									</>
								);
							},
						},
						{
							key: "created_at",
							label: "Date",
							fieldType: "custom",
							noMultiEdit: true,
							customComponent: ({
								changeValue,
								multi,
								inactive,
								mismatch,
								options,
								state,
							}) => {
								const inactiveState = inactive ? "inactive" : undefined;

								const Date = (state.current ?? {}) as Message["created_at"];

								return (
									<>
										<TableStyled.Item key={options.label}>
											<TableStyled.ItemText state={inactiveState}>
												Date and time:
											</TableStyled.ItemText>
											<TableStyled.ItemValue
												spellCheck={false}
												disabled
												state={inactiveState}
												value={`${formatDateTime(Date)}`}
												type={options.type ?? "text"}
											/>
										</TableStyled.Item>
									</>
								);
							},
						},

						{
							key: "content",
							label: "Announcement",
							fieldType: "custom",
							noMultiEdit: true,
							customComponent: ({
								changeValue,
								multi,
								inactive,
								mismatch,
								options,
								state,
							}) => {
								const inactiveState = inactive ? "inactive" : undefined;

								const message = (state.current ?? {}) as Message["content"];

								return (
									<>
										<TableStyled.Item key={options.label}>
											<TableStyled.ItemText state={inactiveState}>
												Announcement:
											</TableStyled.ItemText>
											<TableStyled.Textarea
												spellCheck={true}
												disabled
												state={inactiveState}
												value={message}
												//type={options.type ?? "custom"}
											/>
										</TableStyled.Item>
									</>
								);
							},
						},
						{
							key: "expired",
							label: "Expire Message",
							type: "checkbox",
							fieldType: "checkbox",
							mode: "editOnly",
						},
					]}
					createItem={createItem}
					updateItem={updateItem}
					sidebarUpdateProp={sidebarUpdate}
				/>
			)}
		</Box>
	);
}
