import QRCode from 'qrcode';

export const generateQRCode = async (data: string, sidebarRef: React.RefObject<HTMLDivElement>) => {
    try {
        // Create a new canvas element
        const canvas = document.createElement("canvas");
        
        // Generate a QR code and render it on the canvas
        await QRCode.toCanvas(canvas, data);
        
        // Create a new image element
        const image = document.createElement("img");
        
        // Set the source of the image to the data URL representation of the canvas
        image.src = canvas.toDataURL(); // Convert canvas to data URL
        
        // Get the reference to the sidebar element
        const sidebarElement = sidebarRef.current;
        
        // Check if the sidebar element exists
        if (sidebarElement) {
            // Clear the previous content of the sidebar element
            sidebarElement.innerHTML = "";
            
            // Append the generated QR code image to the sidebar element
            sidebarElement.appendChild(image);
            
            // Log a success message to the console
            console.log("QR code generated successfully");
        }
    } catch (err) {
        // Log an error message to the console if an error occurs during QR code generation
        console.error("An error occurred while generating the QR code:", err);
    }
};
//code is then called at tags.tsx in function QRCodeField()
