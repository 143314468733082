import { styled } from "../../stitches.config";
import * as Switch from "@radix-ui/react-switch";

export const Body = styled("div", {
	marginInline: "20px",
	height: "100%",
	position: "relative",
});

export const Title = styled("p", {
	color: "#858585",
	fontSize: "26px",
});

export const Item = styled("div", {
	width: "100%",
});

export const ItemText = styled("p", {
	color: "#858585",
	fontSize: "20px",
	transition: "color .10s",

	variants: {
		state: {
			inactive: {
				color: "#D6D6D6",
			},
		},
	},
});

export const ItemValue = styled("input", {
	borderWidth: "0px 0px 1px 0px",
	borderStyle: "solid",
	borderColor: "#929292",
	color: "#929292",
	width: "100%",
	outline: "none",
	fontSize: "15px",
	transition: "color .10s",

	"&:disabled": {
		backgroundColor: "transparent",
	},

	variants: {
		state: {
			inactive: {
				color: "#D6D6D6",
				borderColor: "#D6D6D6",
			},
		},
	},
});

export const CheckboxItem = styled("span", {
	color: "#858585",
	fontSize: "20px",
	transition: "color .10s",

	variants: {
		state: {
			inactive: {
				color: "#D6D6D6",
			},
		},
	},
});

export const CheckboxItemRoot = styled(Switch.Root, {
	all: "unset",
	width: 42,
	height: 25,
	borderRadius: "9999px",
	borderWidth: "2px",
	borderStyle: "solid",
	borderColor: "#2B5C77",
	position: "relative",

	color: "#929292",
	transition: "color .10s",

	"&:disabled": {
		opacity: "25%",
	},

	"&:enabled": {
		cursor: "pointer",
	},

	variants: {
		state: {
			inactive: {
				color: "#D6D6D6",
				borderColor: "#D6D6D6",
			},
		},
	},
});

export const CheckboxItemThumb = styled(Switch.Thumb, {
	display: "block",
	width: 21,
	height: 21,
	backgroundColor: "#2B5C77",
	borderRadius: "9999px",
	transition: "transform 100ms",
	transform: "translateX(2px)",
	willChange: "transform",
	'&[data-state="checked"]': { transform: "translateX(calc(100% - 2px))" },

	variants: {
		state: {
			inactive: {
				color: "#D6D6D6",
				borderColor: "#D6D6D6",
			},
			mismatch: {
				opacity: "25%",
			},
		},
	},
});

export const Button = styled("button", {
	padding: "10px 20px",
	backgroundColor: "",
	minWidth: "153px",
	height: "42px",
	color: "white",
	background: "#2B5C77",
	borderRadius: "3px",
	float: "right",
	position: "relative",
	top: "30px",
	right: "10px",
	border: "none",
	cursor: "pointer",
	transition: "background .15s",

	variants: {
		state: {
			inactive: {
				cursor: "default",
				background: "#2B5C7750",
			},
		},
	},
});

export const Submit = styled("span", {});

export const Textarea = styled("textarea", {
	width: "100%",
	minHeight: "150px",
	padding: "10px",
	fontSize: "15px",
	border: "1px solid #929292",
	color: "#929292",
	borderRadius: "3px",
	outline: "none",
	resize: "vertical",
	transition: "border-color .10s",

	"&:disabled": {
		backgroundColor: "transparent",
	},

	"&:enabled": {
		cursor: "text",
	},

	variants: {
		state: {
			inactive: {
				color: "#D6D6D6",
				borderColor: "#D6D6D6",
			},
		},
	},
});

export const TabButton = styled("span", {
	// DEFAULT CSS HERE!
	padding: 5,

	variants: {
		state: {
			selected: {
				// ADDITONAL CSS WHEN SELECTED!
				fontWeight: "bold",
			},
		},
	},
});
