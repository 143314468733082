import React from "react";
import { useState, useEffect } from "react";
import * as Styled from "./table-sidebar.styled";
import { useSidebarState } from "../sidebar";
import { ApiError } from "../../api/api";

export type FieldType = "text" | "checkbox" | "dropdown" | "custom";

export type FieldValueType<TType extends FieldType, TEnum = any> = Pick<
	{
		text: string;
		checkbox: boolean;
		dropdown: TEnum;
		/** UNDEFINED IS NOT VALID! */
		custom: any;
	},
	TType
>[TType];

type InputState<T, TType extends FieldType, TEnum = any> = {
	opts: InputOptions<T, TType>;
	current: FieldValueType<TType, TEnum>;
	isEdited: boolean;
	identicalValue: FieldValueType<TType, TEnum> | undefined;
};

export type CustomComponentProps<T, TType extends FieldType, TEnum = any> = {
    state: Readonly<Omit<InputState<T, TType, TEnum>, "opts">>;
    options: Readonly<InputOptions<T, TType, TEnum>>;
    /** Field is disabled because it cannot be multiedited */
    inactive: boolean;
    /** Field has multiple different values */
    mismatch: boolean;
    multi: boolean;
    changeValue: (value: any) => void;
}

export type InputOptions<
	T,
	TType extends FieldType = FieldType,
	TEnum = any
> = {
	/** The key of the object which to show an input field for */
	key: keyof T;
	/** Whether to show this only on the edit or add sidebar */
	mode?: "addOnly" | "editOnly";
	/** The text shown above the input field */
	label: string;
	/** What kind of input is shown for this option */
	fieldType: TType;
	/** Default value used if no default object was passed or the passed objects have differing values */
	default?: FieldValueType<TType, TEnum>;
	/** The values the enum dropdown can show */
	enumValues?: TType extends "dropdown" ? TEnum[] : undefined;
	/** Custom component when using custom field */
	customComponent?: TType extends "custom"
		? (props: CustomComponentProps<T, TType, TEnum>) => JSX.Element
		: never;
	/** `type` passed to the input element */
	type?: Pick<
		{
			text: React.HTMLInputTypeAttribute;
			checkbox: never | "checkbox";
			dropdown: never | "text";
			custom: string;
		},
		TType
	>[TType];
	/** `Disallow editing this input when multiediting */
	noMultiEdit?: boolean;
};

export type CreateItemFunc<T> = (item: Partial<T>) => Promise<T>;
export type UpdateItemFunc<T> = (oldItem: T, item: Partial<T>) => Promise<T>;

export type TableSidebarProps<T> = {
	/** Inputs shown in the edit fields */
	inputs: InputOptions<T, FieldType, any>[];
	/** Objects to use for default values */
	items: T[];
	/** Text appearing in the title */
	title: string;
	/** Text appearing inside the submit button */
	submitText: string;
} & (
	| {
			submiterType: "ADD";
			createItem: CreateItemFunc<T>;
			updateItem?: UpdateItemFunc<T>;
			onFinish(result: T | undefined, error: ApiError | undefined): void;
	  }
	| {
			submiterType: "EDIT";
			createItem?: CreateItemFunc<T>;
			updateItem: UpdateItemFunc<T>;
			onFinish(
				result: Array<T | undefined>,
				error: Array<ApiError | undefined>
			): void;
	  }
);

/**
 * Provides a component that can be used as the content of sidebar.
 * Will show a list of fields based on the provided inputs.
 */
export default function TableSidebar<T>(props: TableSidebarProps<T>) {
	const { inputs, items, onFinish, submiterType, createItem, updateItem } =
		props;
	const { setHasChanges } = useSidebarState();
	const [edited, setEdited] = useState(false);
	const [states, setStates] = useState<
		Readonly<InputState<T, FieldType, any>>[]
	>([]);
	const [loading, setLoading] = useState(false);

	const zero = items.length === 0;
	const multi = items.length > 1;

	// Initialize states
	if (states.length === 0) {
		states.length = inputs.length;
		for (let i = 0; i < states.length; i++) {
			const input = inputs[i];

			// Gross
			let identical =
				!zero && items.every((item) => item[input.key] === items[0][input.key]);
			let identicalValue = identical ? items[0][input.key] : undefined;
			identical &&= identicalValue !== undefined || identicalValue !== null;
			identicalValue = identical ? identicalValue : undefined;

			switch (input.fieldType) {
				case "text": {
					const newState: InputState<T, "text"> = {
						opts: input as InputOptions<T, "text", any>,
						current: identical
							? identicalValue
							: input.default === undefined
							? ""
							: input.default,
						isEdited: false,
						identicalValue: identicalValue as string,
					};
					states[i] = newState;
					break;
				}
				case "checkbox": {
					const newState: InputState<T, "checkbox"> = {
						opts: input as InputOptions<T, "checkbox", any>,
						current: identical
							? identicalValue
							: input.default === undefined
							? false
							: input.default,
						isEdited: false,
						identicalValue: identicalValue as boolean,
					};
					states[i] = newState;
					break;
				}
				case "dropdown": {
					const newState: InputState<T, "dropdown"> = {
						opts: input as InputOptions<T, "dropdown", any>,
						current: identical
							? identicalValue
							: input.default === undefined
							? 0
							: input.default,
						isEdited: false,
						identicalValue: identicalValue as any,
					};
					states[i] = newState;
					break;
				}
				case "custom":
				default: {
					const newState: InputState<T, "custom"> = {
						opts: input as InputOptions<T, "custom", any>,
						current: identical
							? identicalValue
							: input.default === undefined
							? null
							: input.default,
						isEdited: false,
						identicalValue: identicalValue as any,
					};
					states[i] = newState;
					break;
				}
			}
		}
	}

	useEffect(() => {
		setStates([]);
		setEdited(false);
		setLoading(false);
	}, [inputs, items]);

	function onChange(value: any, state: InputState<T, FieldType, any>) {
		state.current =
			state.opts.fieldType === "checkbox" ? !state.current : value;
		const startedIdentical = state.identicalValue !== undefined;
		if (multi && !startedIdentical) {
			state.isEdited ||=
				startedIdentical && state.identicalValue === state.current
					? false
					: true;
		} else {
			state.isEdited =
				startedIdentical && state.identicalValue === state.current
					? false
					: true;
		}
		setEdited(states.some((v) => v.isEdited));
		setHasChanges(states.some((v) => v.isEdited));
		setStates([...states]);
	}

	function onSubmit(event: React.SyntheticEvent<HTMLFormElement>) {
		event.preventDefault();
		(event.target as any).checkValidity();
		if (submiterType === "ADD") {
			let submit: Partial<T> = {};
			for (const state of states) {
				submit[state.opts.key] = state.isEdited
					? state.current
					: state.identicalValue === undefined
					? state.opts.default
					: state.identicalValue;
			}
			doCreateItem(submit);
		} else {
			const submits = [];
			for (const item of items) {
				let submit: Partial<T> = {};
				for (const state of states) {
					submit[state.opts.key] = state.isEdited
						? state.current
						: state.identicalValue === undefined
						? item[state.opts.key]
						: state.identicalValue === undefined
						? item[state.opts.key]
						: state.identicalValue;
				}
				submits.push(submit);
			}
			doUpdateItems(submits);
		}
	}

	async function doCreateItem(submit: Partial<T>) {
		setLoading(true);
		try {
			const result = await createItem!(submit);
			setLoading(false);
			doOnFinish([result], [undefined]);
		} catch (e) {
			setLoading(false);
			doOnFinish([undefined], [e as ApiError]);
		}
	}

	async function doUpdateItems(submits: Partial<T>[]) {
		setLoading(true);
		const promises = submits.map((v, i) => updateItem!(items[i], v));
		const setled = await Promise.allSettled(promises);
		const results = setled.map((v) =>
			v.status === "fulfilled" ? v.value : undefined
		);
		const errors = setled.map((v) =>
			v.status === "rejected" ? (v.reason as ApiError) : undefined
		);
		setLoading(false);
		doOnFinish(results, errors);
	}

	function doOnFinish(
		results: (T | undefined)[],
		errors: (ApiError | undefined)[]
	) {
		if (submiterType === "ADD") {
			onFinish(results[0], errors[0]);
		} else {
			onFinish(results, errors);
		}
	}

	return (
		<Styled.Body>
			<form onSubmit={onSubmit}>
				<Styled.Item>
					<Styled.Title>{submiterType === "ADD" ? "Add" : "Edit"}</Styled.Title>
				</Styled.Item>

				{states.map((state, i) => {
					const disabled = multi && state.opts.noMultiEdit;
					const inactive = !state.opts.noMultiEdit || !multi ? false : true;
					const inactiveState = inactive ? "inactive" : undefined;
					const mismatch =
						!disabled &&
						multi &&
						state.identicalValue === undefined &&
						!state.isEdited;
					const mismatchState = mismatch ? "mismatch" : undefined;

					switch (state.opts.fieldType) {
						case "text":
							return (
								<Styled.Item key={i}>
									<Styled.ItemText state={inactiveState}>
										{state.opts.label}:
									</Styled.ItemText>
									<Styled.ItemValue
										spellCheck={false}
										disabled={disabled}
										state={inactiveState}
										value={state.current ?? ""}
										type={state.opts.type ?? "text"}
										onChange={(e) => onChange(e.target.value, state)}
									/>
								</Styled.Item>
							);

						case "checkbox":
							return (
								<Styled.CheckboxItem key={i}>
									<Styled.ItemText state={inactiveState}>
										{state.opts.label}:
									</Styled.ItemText>
									<Styled.CheckboxItemRoot
										disabled={disabled}
										state={inactiveState}
										checked={state.current ?? false}
										onCheckedChange={(v) => onChange(v, state)}
									>
										<Styled.CheckboxItemThumb
											state={mismatchState ?? inactiveState}
										/>
									</Styled.CheckboxItemRoot>
								</Styled.CheckboxItem>
							);

						case "custom":
							if (!state.opts.customComponent) {
								throw Error(
									"When using custom fieldType, define a customComponent."
								);
							}
							const CustomComponent = state.opts.customComponent;
							return (
								<CustomComponent
									key={i}
									state={state}
									options={state.opts as any}
									inactive={inactive}
									mismatch={mismatch}
									multi={multi}
									changeValue={(v) => onChange(v, state)}
								/>
							);

						case "dropdown":
						default:
							throw Error(`Unsupported fieldType: ${state.opts.fieldType}`);
					}
				})}

				<Styled.Button
					disabled={!edited && !zero}
					state={edited || zero ? undefined : "inactive"}
					type="submit"
				>
					{loading
						? "Loading..."
						: submiterType === "ADD"
						? "Add row"
						: "Submit changes"}
				</Styled.Button>
			</form>
		</Styled.Body>
	);
}

export type CustomFieldComponentProps<T> = {
	state: Readonly<Omit<InputState<T, "custom", any>, "opts">>;
	options: Readonly<InputOptions<T, "custom", any>>;
	inactive: boolean;
	mismatch: boolean;
	changeValue: (value: any) => void;
};
