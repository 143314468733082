import { useMemo } from 'react';
import { Message, Messages, MessageTemplate } from '../models/messages';
import {
  FORMAT,
  ProcessedApiRequestResult,
  ProcessedApiResult,
  useApi,
  useApiRequest,
} from '../api';

const PATH = 'messages';
const MESSAGE_FORMAT_TREE = { created_at: FORMAT };
const MESSAGES_FORMAT_TREE = { messages: [MESSAGE_FORMAT_TREE] as const };

export type UseListMessagesResult = ProcessedApiResult<{
  /** List of messages if the request succeeded. */
  messages: Message[] | undefined;
  /** Whether there are more messages to show if the request succeeded. */
  hasMore: boolean | undefined;
}>;

export type UseListMessagesParams = {
  /** Type of the messages to list. */
  target_type: 'group' | 'tag';
  /** Target of the messages. */
  target_id: number;
  /** Page number, starting from 0. */
  page: number;
  /** Number of items to return (max: 1000). */
  limit: number;
};

/**
 * React hook for listing all messages (in pages).
 * @param params Object containing the parameters for the request:
 * - `target_type` Type of the messages to list.
 * - `target_id` Target of the messages.
 * - `page` Page number, starting from 0.
 * - `limit` Number of items to return (max: 1000).
 * @returns A list of messages and a bool indicating whether there are more messages.
 */
export function useListMessages(
  params: UseListMessagesParams,
): UseListMessagesResult;
export function useListMessages({
  page,
  limit,
  target_id,
  target_type,
}: UseListMessagesParams): UseListMessagesResult {
  const params = useMemo(
    () => ({ limit, page, target_id, target_type }),
    [limit, page, target_id, target_type],
  );
  const res = useApi<Messages, UseListMessagesParams>({
    path: `${PATH}/`,
    params,
    formatTree: MESSAGES_FORMAT_TREE,
  });
  return useMemo(
    () => ({
      ...res,
      messages: res.result?.messages,
      hasMore: res.result?.has_more,
    }),
    [res],
  );
}

export type UseCreateMessageResult = ProcessedApiRequestResult<{
  /** The newest created message with a unique `id`. */
  message: Message | undefined;
  /**
   * Create a new message.
   * @param template The template for the message to create.
   * @returns The newly created message.
   */
  createMessage(template: MessageTemplate): Promise<Message>;
}>;

/**
 * Hook for creating new messages.
 */
export function useCreateMessage(): UseCreateMessageResult {
  const res = useApiRequest<Message, MessageTemplate>({
    path: `${PATH}/`,
    method: 'POST',
    formatTree: MESSAGE_FORMAT_TREE,
  });
  return useMemo(
    () => ({ ...res, message: res.result, createMessage: res.call }),
    [res],
  );
}

export type UseExpireMessageResult = ProcessedApiRequestResult<{
  /** The newest expired message. */
  message: Message | undefined;
  /**
   * Expire the message.
   * @param id id of the message to expire.
   */
  expireMessage(id: string): Promise<Message>;
}>;

/**
 * Hook for expiring messages.
 * @returns The updated message.
 */
export function useExpireMessage(): UseExpireMessageResult {
  const res = useApiRequest<Message, Pick<Message, 'expired'>>({
    path: `${PATH}/{0}/`,
    method: 'PUT',
    formatTree: MESSAGE_FORMAT_TREE,
  });
  return useMemo(
    () => ({
      ...res,
      message: res.result,
      expireMessage: id => res.call(id, { expired: true }),
    }),
    [res],
  );
}
