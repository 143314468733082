import { useMemo } from 'react';
import {
  NoId,
  ProcessedApiRequestResult,
  ProcessedApiResult,
  useApi,
  useApiRequest,
} from '../api';
import { TagActivation, MinimalTag, Tag, TagInfo, Tags } from '../models/tag';
import { Event } from '../models/events';

const PATH = 'tag';

export type UseListTagsResult = ProcessedApiResult<{
  /** List of tags if the request succeeded. */
  tags: Tag[] | undefined;
}>;

/**
 * React hook for listing all tags (in pages).
 * @param page Page number, starting from 0.
 * @param limit Number of items to return (max: 1000).
 * @returns A list of tags.
 */
export function useListTags(page: number, limit: number): UseListTagsResult {
  const params = useMemo(() => ({ page, limit }), [limit, page]);
  const res = useApi<Tags>({
    path: `${PATH}/`,
    params,
  });
  return useMemo(() => ({ ...res, tags: res.result?.tags }), [res]);
}

export type UseGetTagResult = ProcessedApiResult<{
  /** The tag if the request succeeded. */
  tag: Tag | undefined;
}>;

/**
 * React hook for getting a single tag.
 * @param id Identifier of tag.
 * @returns The tag with matching `id`.
 */
export function useGetTag(id: number): UseGetTagResult {
  const res = useApi<Tag>({
    path: `${PATH}/${id}/`,
  });
  return useMemo(() => ({ ...res, tag: res.result }), [res]);
}

export type UseCreateTagResult = ProcessedApiRequestResult<{
  /** The newest created tag with a unique `id`. */
  tag: Tag | undefined;
  /**
   * Create a new tag.
   * @param tag The Tag to create.
   * @returns The created tag.
   */
  createTag: (tag: NoId<MinimalTag>) => Promise<Tag>;
}>;

/**
 * Hook for creating new tags.
 */
export function useCreateTag(): UseCreateTagResult {
  const res = useApiRequest<Tag, NoId<MinimalTag>>({
    path: `${PATH}/`,
    method: 'POST',
  });
  return useMemo(
    () => ({ ...res, tag: res.result, createTag: res.call }),
    [res],
  );
}

export type UseUpdateTagResult = ProcessedApiRequestResult<{
  /** The newest updated tag. */
  tag: Tag | undefined;
  /**
   * Update the tag.
   * @param tag The new value for the tag.
   * @returns The updated tag.
   */
  updateTag: (tag: MinimalTag) => Promise<Tag>;
}>;

/**
 * Hook for updating tags.
 */
export function useUpdateTag(): UseUpdateTagResult {
  const res = useApiRequest<Tag, NoId<MinimalTag>>({
    path: `${PATH}/{0}/`,
    method: 'PUT',
  });
  return useMemo(
    () => ({
      ...res,
      tag: res.result,
      updateTag: tag => res.call(tag.id, tag),
    }),
    [res],
  );
}

export type UseGetTagInfoResult = ProcessedApiRequestResult<{
  /** The newest tag info. */
  event: TagInfo | undefined;
  /**
   * Gets the info of the tag.
   * @param token Token of the tag.
   * @param body Empty body.
   * @returns The tag info.
   */
  getTagInfo: (token: string, body: {}) => Promise<TagInfo>;
}>;

/**
 * Hook for getting info of a tag.
 */
export function useGetTagInfo(): UseGetTagInfoResult {
  const res = useApiRequest<TagInfo, {}>({
    path: `t/{0}/`,
    omitApiPath: true,
    expectCode: 449,
    method: 'POST',
  });
  return useMemo(
    () => ({
      ...res,
      event: res.result,
      getTagInfo: res.call,
    }),
    [res],
  );
}

export type UseActivateTagResult = ProcessedApiRequestResult<{
  /** The newest created event id. */
  event: Pick<Event, 'id'> | undefined;
  /**
   * Creates an event based on the tag.
   * @param token Token of the tag.
   * @param activation The base of the event that is created.
   * @returns The created event id.
   */
  activateTag: (
    token: string,
    activation: TagActivation,
  ) => Promise<Pick<Event, 'id'>>;
}>;

/**
 * Hook for creating events based on a tag.
 */
export function useActivateTag(): UseActivateTagResult {
  const res = useApiRequest<Pick<Event, 'id'>, TagActivation>({
    path: `t/{0}/`,
    omitApiPath: true,
    method: 'POST',
  });
  return useMemo(
    () => ({
      ...res,
      event: res.result,
      activateTag: res.call,
    }),
    [res],
  );
}
