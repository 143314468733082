import React, { useEffect, useState } from "react";
import * as Styled from "./sidebar-components/tags-sidebar.styled";
import { ReactComponent as Arrow } from "../Assets/tag-message-arrow.svg";
//import { useLinkClickHandler } from "react-router-dom";
import { Tag } from "../api/models/tag";
// import { ApiError } from "../api/api";
import { TableSidebarProps } from "./sidebar-components/table-sidebar";
// import { UpdateItemFunc } from "./sidebar-components/table-sidebar";
// import { randomInt } from "crypto";
import {
	useCreateMessage,
	useListMessages,
	// useExpireMessage,
	UseListMessagesParams,
} from "../api/apis/messages";
import {
	Message,
	// MessageTemplate,
} from "../api/models/messages";

export const GetMessages = ({
	tags,
	refreshCounter,
}: UseListMessagesParams | any) => {
	const [tagMessagesJSX, setTagMessagesJSX] = useState<any | []>();

	const { messages, refresh } = useListMessages(tags);

	const formatDate = (dateString: string) => {
		const date = new Date(dateString);
		const formattedDate = date.toLocaleString(); // Adjust the formatting based on your requirements
		return formattedDate;
	};

	useEffect(() => {
		if (messages && messages.length === 0) {
			setTagMessagesJSX(<Styled.Paragraph>This tag doesn't have any messages</Styled.Paragraph>);
		} else if (messages) {
			setTagMessagesJSX(
				messages.map((el: Message) => (
					<Styled.Box key={el.id}>
						<Styled.Date>{formatDate(String(el.created_at))}</Styled.Date>
						{el.content}
					</Styled.Box>
				))
			);
		} else {
			setTagMessagesJSX(null);
		}
	}, [messages]);

	useEffect(() => {
		refresh();
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [refreshCounter]);

	return <div>{tagMessagesJSX}</div>;
};

export default function TagMessages(props: TableSidebarProps<Tag>) {
	const { items } = props;
	const [currentMessage, setCurrentMessage] = useState("");
	const { createMessage, loading } = useCreateMessage();
	const [refreshCounter, setRefreshCounter] = useState(false);

	// const tagExampleText =
	// 	"Example message. This is a box to show old messages for the tag. If no old messages are available, this box won’t be here.";

	const handleInputChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
		setCurrentMessage(e.target.value);
	};

	const clickHandler = async () => {
		if (loading) {
			return;
		}

		for (const item of items) {
			const newMessage = {
				content: currentMessage,
				target_type: "tag",
				target_id: item.id.toString(),
				expire_previous: false,
			};

			try {
				await createMessage(newMessage);
			} catch (error) {
				console.error("Error creating message:", error);
			}
		}

		setRefreshCounter(true);

		setCurrentMessage("");
	};

	return (
		<>
			<Styled.Container>
				{items.map((item) => {
					const tag: UseListMessagesParams = {
						target_type: "tag",
						target_id: item.id,
						page: 0,
						limit: 1000,
					};

					return (
						<GetMessages
							tags={tag}
							key={tag.target_id}
							refreshCounter={refreshCounter}
						/>
					);
				})}
			</Styled.Container>
			<Styled.InputContainer>
				<Styled.InputDiv>
					<Styled.Input
						name="text"
						value={currentMessage}
						onChange={handleInputChange}
						placeholder="Here you can write your own text for this tag."
					/>
					<Styled.Arrow onClick={clickHandler}>
						<Arrow />{" "}
					</Styled.Arrow>
				</Styled.InputDiv>
			</Styled.InputContainer>
		</>
	);
}
