import { useMemo } from 'react';
import {
  FORMAT,
  ProcessedApiRequestResult,
  ProcessedApiResult,
  useApi,
  useApiRequest,
} from '../../api';
import { Message, Messages } from '../../models/messages';

const PATH = 'user/messages';
const MESSAGE_FORMAT_TREE = { created_at: FORMAT };
const MESSAGES_FORMAT_TREE = { messages: [MESSAGE_FORMAT_TREE] as const };

export type UseGetUserMessagesResult = ProcessedApiResult<{
  /** List of messages if the request succeeded. */
  messages: Message[] | undefined;
  /** Whether there are more messages to show if the request succeeded. */
  hasMore: boolean | undefined;
}>;

/**
 * React hook for getting the messages of the current user.
 * @param page Page number, starting from 0.
 * @param limit Number of items to return (max: 1000, default: 10).
 * @returns The messages of the current user.
 */
export function useGetUserMessages(
  page = 0,
  limit = 10,
): UseGetUserMessagesResult {
  const params = useMemo(() => ({ page, limit }), [limit, page]);
  const res = useApi<Messages>({
    path: `${PATH}/`,
    params,
    formatTree: MESSAGES_FORMAT_TREE,
  });
  return useMemo(
    () => ({
      ...res,
      messages: res.result?.messages,
      hasMore: res.result?.has_more,
    }),
    [res],
  );
}

export type UseMarkMessageReadResult = ProcessedApiRequestResult<{
  /**
   * Mark a message read.
   * @param id Id of the message to mark read.
   */
  markMessageRead(id: string): Promise<unknown>;
}>;

/**
 * THIS HOOK IS NOT WORKING!
 * React hook for marking a message read.
 * @returns Unknown.
 */
export function useMarkMessageRead(): UseMarkMessageReadResult {
  const res = useApiRequest<unknown>({
    path: `messages/{0}/read/`,
  });
  return useMemo(
    () => ({
      ...res,
      markMessageRead: res.call,
    }),
    [res],
  );
}
