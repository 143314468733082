import { styled } from '../stitches.config'

import * as Icons from '@radix-ui/react-icons'
import * as NavigationMenu from '@radix-ui/react-navigation-menu'

export const ToolbarMenu = styled(NavigationMenu.Root, {
    position: 'fixed',
    backgroundColor: 'white',
    top: '0',
    left: '0',
    width: '100vw',
    height: '73px',
    boxShadow: '4px 0px 8px rgba(0, 0, 0, 0.38)',
    transition: 'padding .2s',
    padding: '0 20px',
    boxSizing: 'border-box',
    zIndex: 1,
    
    'div': {
        height: 'inherit',
        maxWidth: 'inherit'
    }
})

export const NavigationList = styled(NavigationMenu.List, {
    listStyle: 'none',
    display: 'flex',
    flexDirection: 'row',
    height: 'inherit',
    justifyContent: 'space-between',
    margin: 0,
    padding: 0
})

export const NavigationItem = styled(NavigationMenu.Item, {
    display: 'flex',
    alignItems: 'center'
})

export const SearchInput = styled('input', {
    background: '#efefef',
    minWidth: '300px',
    boxSizing: 'border-box',
    borderRadius: '60px',
    minHeight: '36px',
    border: 0,
    outline: 'none',
    padding: '0 54px 0 20px',
    fontSize: '14px',
    color: '$searchBlue',
    marginLeft: '43px',

    '&:focus': {
        boxShadow: '0px 0px 0px 1px #7B9CE3'
    }
})

export const MagnifyingGlass = styled(Icons.MagnifyingGlassIcon, {
    color: '$searchBlue',
    translate: '-40px',
    cursor: 'pointer'
})

export const CompanyName = styled('span', {
    marginRight: '34px',
    fontSize: '15px',
    color: '$primary',
    cursor: 'default'
})

export const AvatarRoot = styled('span', {
    width: '48px',
    height: '48px',
    marginLeft: '12px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: 'gray',
    borderRadius: '50%',
    cursor: 'pointer',

    'svg': {
        height: '31px',
        width: '31px',
        color: 'white'
    }
})
