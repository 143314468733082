import { BrowserRouter, Routes, Route, Outlet } from 'react-router-dom'
import { Box } from './components/general-purpose'
import * as Login from './components/login'

import Navbar from './components/navbar'


import ActivitiesPage from './components/activities'
import DashboardPage from './components/dashboard'

import EventsPage from './components/events'
import AnnouncementsPage from './components/announcements'

import ReportsPage from './components/reports'
import SettingsPage from './components/settings'

import TagsPage from './components/tags'
import UsersPage from './components/users'

import { Sidebar, SidebarProvider } from './components/sidebar'

import { AuthProvider } from 'oidc-react';
import { oidcConfig } from './api/oauth2/oauth';
import { Callback } from './components/callback';

const PageLayout = () => {
    return (
        <SidebarProvider>
            <Box css={{display: 'flex', flexDirection: 'row', position: 'fixed', top: '0', width: '100vw'}}>
                <Navbar />

                <Box css={{position: 'relative', backgroundColor: '#f5f6fa', width: '100vw'}}>
                    <Outlet />
                </Box>
                <Sidebar />
            </Box>
        </SidebarProvider>
    )
}

const ProtectedPage = () => {
    if (sessionStorage.getItem("apiToken") && sessionStorage.getItem("tokenExpires") && Number(sessionStorage.getItem("tokenExpires")) * 1000 > Date.now()) {
        return (
            <BrowserRouter>
                <Routes>
                    <Route path='auth' element={<Login.PageLayout />}>
                        <Route path='login' element={<Login.LoginPage />} />
                        <Route path='forgot-password' element={<Login.ForgotPasswordPage />} />
                        <Route path='reset-password' element={<Login.ResetPasswordPage />} />
                    </Route>
                    <Route path='/' element={<PageLayout />}>
                        <Route path='dashboard' element={<DashboardPage />} />
                        <Route path='activities' element={<ActivitiesPage />} />
                        <Route path='events' element={<EventsPage />} />
                        <Route path='messages' element={<AnnouncementsPage />} />
                        <Route path='reports' element={<ReportsPage />} />
                        <Route path='settings' element={<SettingsPage />} />
                        <Route path='tags' element={<TagsPage />} />
                        <Route path='users' element={<UsersPage />} />
                        <Route path="oauth2/callback/" element={<Callback/>}/>
                        
                    </Route>
                </Routes>
            </BrowserRouter>
        )
    } else {
        Object.keys(sessionStorage).forEach((key) => {
            if (/oidc/.test(key)) {
                sessionStorage.removeItem(key);
            }
        })
        return (
            <BrowserRouter>
                <Routes>
                    <Route path='/' element={<PageLayout />}>
                        <Route path="oauth2/callback/" element={<Callback/>}/>
                    </Route>
                </Routes>
            </BrowserRouter>
        )
    }
}

export default function App() {
    return (
    <AuthProvider {...oidcConfig}>
        <ProtectedPage />
    </AuthProvider>
    )
}
