import { styled } from "../../stitches.config";

export const TabButton = styled("span", {
	// DEFAULT CSS HERE!
	padding: 5,

	variants: {
		state: {
			selected: {
				// ADDITONAL CSS WHEN SELECTED!
				fontWeight: "bold",
			},
		},
	},
});

export const TableHeaderStyles = {
	mainContainer: {
		backgroundColor: "#EFEFEF",
		color: "#858585",
		textAlign: "left" as "left",
		borderStyle: "none",
		paddingRight: "0px",
	},
	descriptionContainer: {
		width: "124px",
		paddingLeft: "9px",
		borderRadius: "5px 0px 0px 5px",
	},
	locationContainer: {
		width: "124px",
		paddingLeft: "6px",
	},
	inContainer: {
		width: "30px",
		paddingRight: "2px",
		paddingLeft: "21px",
	},
	outContainer: {
		width: "30px",
		padding: "1px 2px",
	},
	emptyContainer: {
		borderStyle: "none",
		borderRadius: "0px 5px 5px 0px",
	},
};

export const TextAreaStyles = {
	mainContainer: {
		backgroundColor: "#EFEFEF",
		color: "#858585",

		"&:hover": {
			backgroundColor: "#000000",
			padding: "500px",
		},
	},

	descriptionContainer: {
		borderStyle: "none",
		borderRadius: "5px 0px 0px 5px",
		paddingLeft: "9px",
		paddingRight: "7px",
	},
	locationContainer: {
		paddingLeft: "6px",
		paddingRight: "0px",
	},
	inContainer: {
		paddingLeft: "24px",
		paddingRight: "2px",
	},
	outContainer: {
		padding: "1px 4px",
	},
	checkmarkContainer: {
		width: "17px",
		height: "17px",
		border: "1px solid #2B5C77",
		borderRadius: "5px",
	},
	uncheckedCheckmarkContainer: {
		width: "17px",
		height: "17px",
		border: "1px solid #858585",
		borderRadius: "5px",
	},
	checkmark: {
		display: "block",
		margin: "auto",
		width: "10px",
		height: "10px",
		marginTop: "3px",
		color: "#2B5C77",
	},

	editButton: {
		borderStyle: "none",
		borderRadius: "0px 5px 5px 0px",
	},

	editButtonPos: {
		borderStyle: "none",
		marginBottom: "20px",
		marginRight: "5px",
	},

	editTextBox: {
		width: "97px",
		height: "94px",
		borderStyle: "none",
		borderRadius: "10px",
		marginTop: "8px",
		marginBottom: "8px",
	},

	trashButtonPos: {
		marginBottom: "80px",
		marginRight: "5px",
		borderStyle: "none",
	},
};

export const TableStyles = {
	container: {
		tableLayout: "fixed",
		borderCollapse: "collapse",
		borderSpacing: "0px 4px",
	},
};

export const SecondTabStyle = styled("div", {
	backgroundcolor: "#efefef",

	width: "100%",
	scrollbarWidth: "none",
	margin: "0",
	padding: "0 20px 10px 20px",
	borderCollapse: "collapse",
	borderSpacing: "0 5px",
	position: "sticky",
});

export const AddActivities = styled("div", {
	color: "#929292",
	borderBottom: "1px solid black",
});

/*

export const MyOtherComponent = styled('div', {
    // STYLE HERE!
})

*/

export const Paragraph = styled("p", {
	fontStyle: "normal",
	fontSize: "15px",
	paddingTop: "10px",
	fontFamily: "Actor",
	textAlign: "center",
	lineHeight: "18px",
	color: "#858585",
});

export const Container = styled("div", {
	marginTop: "15px",
	paddingBlockEnd: "25px",
	minHeight: "370px",
});

export const Box = styled("div", {
	position: "relative",
	display: "flex",
	marginLeft: "5%",
	width: "90%",
	boxSizing: "border-box",
	border: "1px solid #929292",
	borderRadius: "3px",
	color: "#929292",
	paddingLeft: "5px",
	paddingTop: "15px",
	paddingBottom: "15px",
	paddingRight: "20px",
	fontWeight: "400",
	fontSize: "14px",
	fontFamily: "Actor",
	textAlign: "left",
	marginTop: "3px",
	flexDirection: "column",
});

export const Date = styled("time", {
	fontFamily: "Actor",
	fontWeight: "400",
	fontSize: "9px",
	display: "flex",
	alignItems: "flex-end",
	textAlign: "right",
	padding: "3px",
	position: "absolute",
	bottom: "0",
	right: "0",
});

export const InputContainer = styled("div", {
	width: "100%",

	marginBlock: "3px",
	marginBottom: "30px",
	background: "white",
	position: "relative",
	display: "flex",
});

export const InputDiv = styled("div", {
	width: "100%",
	alignContent: "center",
	height: "125px",
	boxSizing: "border-box",
	border: "1px solid #929292",
	borderRadius: "3px",
	color: "#929292",
	padding: "5px",
	fontSize: "14px",
	fontFamily: "Actor",
	marginTop: "10px",
	marginBlock: "3px",
	marginBottom: "30px",
	background: "white",
	position: "relative",
	display: "flex",
});

export const Input = styled("textarea", {
	border: "none",
	outline: "none",
	width: "100%",
	minHeight: "100%",
	fontFamily: "Actor",

	resize: "none" /* Disable textarea resizing */,
	borderRadius: "0" /* Remove the default border-radius */,
});

export const Arrow = styled("button", {
	alignSelf: "flex-end",
	position: "absolute",
	padding: "5px",
	marginRight: "5px",
	bottom: "0",
	right: "0",
	border: "none",
	background: "none",
});
