import { createStitches } from "@stitches/react";

export const { styled } = createStitches({
    theme: {
        colors: {
            primary: 'rgba(43, 92, 119, 1)',
            darkBlue: 'rgba(34, 57, 115, 1)',
            searchBlue: '#89A1D5'
        }
    }
})
