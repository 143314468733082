import * as Styled from './toolbar.styled'
import * as Icons from '@radix-ui/react-icons'

import svepLogo from '../Assets/Images/svep-logo.png'
import { useSidebar } from './sidebar';

const SearchBar = () => {
    return (
        <>
            <Styled.SearchInput placeholder='Search' />
            <Styled.MagnifyingGlass />
        </>
    )
}

const HelpTools = () => {
    const { toggleSidebar } = useSidebar()

    function onBellPress() {
        toggleSidebar("notifications", <h2 style={{ margin: 0, textAlign: 'center'}}>Notifications</h2>)
    }

    function onUserPress() {
        toggleSidebar("user", <h2 style={{ margin: 0, textAlign: 'center'}}>User</h2>)
    }

    return (
        <>
            <Styled.CompanyName>Company Oy</Styled.CompanyName>

            <Styled.AvatarRoot>
                <Icons.BellIcon onClick={onBellPress}/>
            </Styled.AvatarRoot>

            <Styled.AvatarRoot>
                <Icons.FaceIcon onClick={onUserPress}/>
            </Styled.AvatarRoot>
        </>
    )
}

export default function Toolbar() {
    return (
        <Styled.ToolbarMenu>
            <Styled.NavigationList>
                <Styled.NavigationItem>
                    <img src={svepLogo} alt='Svep' width='100' />
                    <SearchBar />
                </Styled.NavigationItem>

                <Styled.NavigationItem>
                    <HelpTools />
                </Styled.NavigationItem>
            </Styled.NavigationList>
        </Styled.ToolbarMenu>
    )
}
