var signIn: boolean = true;
if (sessionStorage.getItem("apiToken")) {
    signIn = false;
    if (Number(sessionStorage.getItem("tokenExpires")) * 1000 < Number(Date.now())) {
        signIn = true;
    }
}

const localUri = window.location.host;
const protocol = window.location.protocol;

export const oidcConfig = {
    onSignIn: (user: any) => {
        sessionStorage.setItem("apiToken", `"${user.access_token}"`)
        sessionStorage.setItem("tokenExpires", `${user.expires_at}`)
        Object.keys(localStorage).forEach((key) => {
            if (/oidc/.test(key)) {
                localStorage.removeItem(key);
            }
        })
    },
    authority: 'https://test.staging.svep.io/oauth2/',
    clientId: '2W6PvsLa9VuLmCX0v4Ct2b2kqQOIcBAFWiiBSsDx',
    redirectUri: protocol + '//' + localUri + '/oauth2/callback/',
    silentRedirectUri: protocol + '//' + localUri + '/',
    responseType: 'code',
    autoSignIn: signIn,
    automaticSilentRenew: true,
    scope: 'svep',
    metadata : {
        authorization_endpoint: "https://test.staging.svep.io/oauth2/authorize/", 
        token_endpoint: "https://test.staging.svep.io/oauth2/token/",
        userinfo_endpoint: "https://test.staging.svep.io/api/v1/user/",
    }  
  };
