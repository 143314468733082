import {useState, useEffect} from 'react';


const API_TOKEN_KEY = 'apiToken';


const useOAuth2Token = () => {
    const [token, setToken] = useState(() => {
        const token = sessionStorage.getItem(API_TOKEN_KEY);

        return token ? JSON.parse(token) as string : '';
    })
    useEffect(() => {
        sessionStorage.setItem(API_TOKEN_KEY, JSON.stringify(token));
    }, [token]);

    return [token, setToken] as const;
}

export default useOAuth2Token;
