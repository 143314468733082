import * as NavigationMenu from '@radix-ui/react-navigation-menu'
import { Link } from "react-router-dom";
import { styled } from '../stitches.config'

export const NavigationBar = styled(NavigationMenu.Root, {
    position: 'relative',
    backgroundColor: 'white',
    top: 0,
    left: 0,
    width: '212px',
    height: '100vh',
    boxShadow: '4px 0px 8px rgba(0, 0, 0, 0.38)',
    transition: 'width .05s',

    'span': {
        marginLeft: '16px'
    },

    variants: {
        size: {
            small: {
                width: '72px',

                'span': {
                    display: 'none'
                },

                'a': {
                    width: '20px'
                }
            }
        }
    }
})

export const NavigationList = styled(NavigationMenu.List, {
    position: 'fixed',
    top: '0',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    paddingTop: '93px',
    paddingLeft: '27px'
})

export const LinkItem = styled(Link, {
    paddingBottom: '16px',
    textDecoration: 'none',
    fontSize: '16px',
    transition: 'color 0.2s ease-in-out',
    color: 'gray',
    cursor: 'pointer',

    '&:hover': {
        color: '$primary'
    },

    '&::before': {
        display: 'inline-block',
        width: '20px',
        marginRight: '16px'
    },

	variants: {
		state: {
			selected: {
				color: "#223973",
			},
		},
	},
})
