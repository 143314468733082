import React from "react";
import { Box } from "./general-purpose";
import * as Icons from "@radix-ui/react-icons";
import * as Styled from "./table.styled";
import TableSidebar, {
	InputOptions,
	CreateItemFunc,
	UpdateItemFunc,
} from "./sidebar-components/table-sidebar";
import { useSidebarState } from "./sidebar";
import {
	Table as ReactTable,
	Column,
	Header,
	flexRender,
	getCoreRowModel,
	ColumnOrderState,
	getSortedRowModel,
	SortingState,
	useReactTable,
	RowSelectionState,
	Row,
	ColumnDef,
} from "@tanstack/react-table";

import { useDrag, useDrop } from "react-dnd";

type ToolsMenuProps<T> = {
	sidebarInputs?: TableProps<T>["sidebarInputs"];
	createItem?: TableProps<T>["createItem"];
	customSidebar?: TableProps<T>["customSidebar"];
	largeAddButton?: TableProps<T>["largeAddButton"];
	largeAddButtonText?: TableProps<T>["largeAddButtonText"];
};

function ToolsMenu<T>(props: ToolsMenuProps<T>) {
	const { setSidebar, hasChanges } = useSidebarState();
	return (
		<Styled.ButtonsHolder size={props.largeAddButton ? "large" : undefined}>
			<Box css={{ opacity: "0.05" }}>
				<Icons.InfoCircledIcon
					width="20px"
					height="20"
					viewBox="1 1 13 13"
				/>
				<Icons.FilePlusIcon
					width="20px"
					height="20"
					viewBox="0 1 11 13"
				/>
				<Icons.TrashIcon
					width="20px"
					height="20"
					viewBox="0 1 9 12"
				/>
			</Box>
			{props.createItem && (
				<button
					onClick={() => {
						if (hasChanges) {
							return;
						}
						if (props.sidebarInputs && props.createItem) {
							const SidebarComponent = props.customSidebar ?? TableSidebar;
							setSidebar(
								<SidebarComponent
									// sidebarRef={sidebarRef}
									title="Create"
									submitText="Add row"
									inputs={props.sidebarInputs}
									items={[]}
									submiterType={"ADD"}
									createItem={props.createItem}
									onFinish={(result, error) => {
										setSidebar(false);
										if (!error) {
											// TODO: Show added item
										}
									}}
								/>
							);
						}
					}}
					style={{ border: "0px", backgroundColor: "#f5f6fa" }}
				>
					{props.largeAddButton ? (
						<Styled.LargeButton>
							{props.largeAddButtonText ?? "New Row"}
						</Styled.LargeButton>
					) : (
						<Icons.PlusIcon
							width="20"
							height="20"
							viewBox="2 1 12 12"
						/>
					)}
				</button>
			)}
		</Styled.ButtonsHolder>
	);
}

const reorderColumn = (
	draggedColumnId: string,
	targetColumnId: string,
	columnOrder: string[]
): ColumnOrderState => {
	columnOrder.splice(
		columnOrder.indexOf(targetColumnId),
		0,
		columnOrder.splice(columnOrder.indexOf(draggedColumnId), 1)[0]
	);
	return [...columnOrder];
};

type DraggableColumnHeaderProps<T> = {
	header: Header<T, unknown>;
	table: ReactTable<T>;
	singleSelect?: boolean;
};

function DraggableColumnHeader<T>({
	header,
	table,
	singleSelect,
}: DraggableColumnHeaderProps<T>) {
	const { getState, setColumnOrder } = table;
	const { columnOrder } = getState();
	const { column } = header;

	const [, dropRef] = useDrop({
		accept: "column",
		drop: (draggedColumn: Column<{}>) => {
			const newColumnOrder = reorderColumn(
				draggedColumn.id,
				column.id,
				columnOrder
			);
			setColumnOrder(newColumnOrder);
		},
	});

	const [, dragRef] = useDrag({
		item: () => column,
		type: "column",
	});

	const isSelect = header.id === "select";
	const hideAllSelect = isSelect && singleSelect;

	return (
		<td
			ref={dropRef}
			colSpan={header.colSpan}
			style={isSelect ? { backgroundColor: "white", cursor: "auto" } : {}}
		>
			<Box
				ref={isSelect ? null : dragRef}
				onClick={header.column.getToggleSortingHandler()}
				css={{
					width: "100%",
					display: "flex",
					justifyContent: "space-between",

					svg: {
						marginLeft: "7px",
					},
				}}
			>
				<div>
					{!hideAllSelect &&
						flexRender(header.column.columnDef.header, header.getContext())}
					{{
						asc: (
							<Icons.TriangleUpIcon
								width="15"
								height="15"
								viewBox="0 1 13 8"
							/>
						),
						desc: (
							<Icons.TriangleDownIcon
								width="15"
								height="15"
								viewBox="0 2 13 8"
							/>
						),
					}[header.column.getIsSorted() as string] ?? null}
				</div>

				<Icons.PauseIcon
					width="20"
					height="20"
					viewBox="0 0 6 14"
					style={isSelect ? { display: "none" } : {}}
					visibility="hidden"
				/>
			</Box>
		</td>
	);
}

export type TableProps<
	T,
	TSidebar extends typeof TableSidebar<T> = typeof TableSidebar<T>
> = {
	data: T[];
	columns: ColumnDef<T>[];
	sorting?: SortingState;
	customSidebar?: TSidebar;
	sidebarInputs: InputOptions<T>[];
	createItem?: CreateItemFunc<T>;
	updateItem?: UpdateItemFunc<T>;
	sidebarUpdateProp: (results: (T | undefined)[]) => void;
	/** Display a svep base colored large button instead of just a + icon */
	largeAddButton?: boolean;
	/** Text for large button */
	largeAddButtonText?: string;
	/** Disable multiple selection */
	singleSelect?: boolean;
};

export function Table<T>(props: TableProps<T>) {
	const data = React.useMemo<T[]>(() => props.data, [props.data]);
	const columns = React.useMemo(() => props.columns, [props.columns]);
	const { setSidebar, hasChanges: sidebarHasChanges } = useSidebarState();

	const [rowSelection, setRowSelection] = React.useState<RowSelectionState>({});
	const [sorting, setSorting] = React.useState<SortingState>(
		props.sorting ?? []
	);

	const [columnOrder, setColumnOrder] = React.useState<ColumnOrderState>(
		columns.map((column: ColumnDef<T>) => column.id as string)
	);

	const table = useReactTable<T>({
		data,
		columns,
		state: {
			sorting,
			rowSelection,
			columnOrder,
		},
		enableSortingRemoval: false,
		enableRowSelection: !sidebarHasChanges,
		onColumnOrderChange: setColumnOrder,
		onRowSelectionChange: setRowSelection,
		onSortingChange: setSorting,
		getCoreRowModel: getCoreRowModel(),
		getSortedRowModel: getSortedRowModel(),
	});

	const handleRowClick = (row: Row<T>) => {
		if (sidebarHasChanges) return;
		if (rowSelection[row.id]) {
			delete rowSelection[row.id];
		} else {
			if (props.singleSelect) {
				Object.keys(rowSelection).forEach((key) => delete rowSelection[key]);
			}
			rowSelection[row.id] = true;
		}
		var selections = Object.keys(rowSelection)
			.filter((k) => rowSelection[k])
			.map((v) => data[~~v]) as T[];
		if (selections.length && props.sidebarInputs && props.updateItem) {
			const SidebarComponent = props.customSidebar ?? TableSidebar;
			setSidebar(
				<SidebarComponent
					title="Edit"
					submitText="Submit changes"
					inputs={props.sidebarInputs}
					items={selections}
					submiterType={"EDIT"}
					updateItem={props.updateItem}
					onFinish={(results, errors) => {
						if (!errors.some((v) => v)) {
							console.log(results);
							setRowSelection({});
							if (props.sidebarUpdateProp) {
								//console.log(results);
								const filteredResults = results.filter(
									(item) => item !== undefined
								);
								props.sidebarUpdateProp(filteredResults);
							}
							setSidebar(false);
						}

						const filteredResults = results.filter(
							(item) => item !== undefined
						);
						props.sidebarUpdateProp(filteredResults);
					}}
				/>,
				null,
				{
					onCloseButton(hasChanges) {
						if (hasChanges) {
							return false;
						}
						Object.keys(rowSelection).forEach(
							(key) => delete rowSelection[key]
						);
						setRowSelection(rowSelection);
						return true;
					},
				}
			);
		} else {
			setSidebar(false);
		}
		setRowSelection(rowSelection);
	};

	return (
		<>
			<ToolsMenu {...props} />

			<Styled.Table>
				<thead>
					{table.getHeaderGroups().map((headerGroup) => (
						<tr key={headerGroup.id}>
							{headerGroup.headers.map((header) => (
								<DraggableColumnHeader
									key={header.id}
									header={header}
									table={table}
									singleSelect={props.singleSelect}
								/>
							))}
						</tr>
					))}
				</thead>
				<tbody>
					{table.getRowModel().rows.map((row) => (
						<tr
							key={row.id}
							onClick={() => handleRowClick(row)}
							style={{ backgroundColor: rowSelection[row.id] ? "#efefef" : "" }}
						>
							{row.getVisibleCells().map((cell) => (
								<td
									key={cell.id}
									style={{
										backgroundColor: row.getIsSelected() ? "#efefef" : "",
									}}
								>
									{flexRender(cell.column.columnDef.cell, cell.getContext())}
								</td>
							))}
						</tr>
					))}
				</tbody>
			</Styled.Table>
		</>
	);
}
