import React from 'react'
import Loader from './help-components/loader'

import { useCreateActivity, useListActivities, useUpdateActivity } from '../api/apis/activity'
import { Activity } from '../api/models/activity'

import { Table } from './table'
import { Box } from './general-purpose'
import { ColumnDef } from '@tanstack/react-table'
import Checkbox from './help-components/checkbox'

export default function GetActivities() {
    const {activities, error, loading, refresh} = useListActivities(0, 1000);
    const [data, setData] = React.useState<Activity[]>([]);
    const {createActivity} = useCreateActivity();
    const {updateActivity} = useUpdateActivity();

    const sidebarUpdate =(results: (Activity | undefined)[]) => {
        console.log("parent");
        results.forEach((user) => {
            console.log(user?.id);
          });
 
 
 setData(data.map(item => results.find(results => results?.id === item.id) ?? item))}  


    const columns = React.useMemo<ColumnDef<Activity>[]>(
        () => [
            {
                id: 'select',
                header: ({table}) => (
                    <Checkbox
                        {...{
                            checked: table.getIsAllRowsSelected(),
                            disabled: !table.options.enableRowSelection,
                            indeterminate: table.getIsSomeRowsSelected(),
                            onChange: table.getToggleAllRowsSelectedHandler()
                        }}
                    />
                ),
                cell: ({row}) => (
                    <Checkbox
                        {...{
                            checked: row.getIsSelected(),
                            disabled: !row.getCanSelect(),
                            indeterminate: row.getIsSomeSelected(),
                            onChange: row.getToggleSelectedHandler()
                        }}
                    />
                ),
            },
            {
                id: 'description',
                accessorKey: 'name',
                header: 'Description',
                cell: info => info.getValue()
            },
            {
                id: 'location',
                accessorKey: 'location',
                header: 'Location',
                cell: info => info.getValue()
            },
            {
                id: 'tags',
                accessorKey: 'tags',
                header: 'Tags',
                cell: info => info.getValue()
            }
        ], []
    )
    
    React.useEffect(() => {
        activities && setData(activities)
    }, [activities])

    if(error) {
        return (
            <>
                <p>Error occurred: {JSON.stringify(error)}</p>
                <button onClick={refresh}>Try again</button>
            </>
        )
    }

    function createItem(item: Partial<Activity>): Promise<Activity> {
        return createActivity({
            name: 'Untitled',
            enabled: true,
            location: '',
            direction: 'both',
            ...item,
        })
    }

    function updateItem(old: Activity, item: Partial<Activity>): Promise<Activity> {
        return updateActivity({
            ...old,
            ...item,
        })
    }

    return (
        <Box css={{height: '100vh', overflowY: 'scroll'}}>
            {loading
                ? <Loader />
                : <Table
                    data = {data}
                    columns = {columns}
                    sorting = {[{
                        id: 'description',
                        desc: false
                    }]}
                    sidebarInputs = {[
                        { key: 'name', label: 'Description', fieldType: "text" },
                        { key: 'direction', label: 'Tag', fieldType: "text" },
                        { key: 'location', label: 'Location', fieldType: "text" },
                    ]}
                    createItem={createItem}
                    updateItem={updateItem}
                    sidebarUpdateProp={sidebarUpdate}
                />
            }
        </Box>
    )  
}
